angular.module('fidis-framework-filter', ['fidis-framework-config', 'fidis-framework-login']);

//integer filter
angular.module('fidis-framework-filter').filter('integer', ['SessionService', '$filter', function(SessionService, $filter){
	return function integer(input){
		if(input != undefined && input != '' && input != null){
			input = $filter('number')(input);
			return input.toString().replace(',', SessionService.session.settings.number_format.group);
		}else{
			return 0;
		}
	};
}]);

//currency filter with positive / negative sign
angular.module('fidis-framework-filter').filter('currency_signed', ['$filter', function($filter){
	return function currency_signed(input){
		return (input < 0)?''+$filter('currency')(input):'+'+$filter('currency')(input);
	};
}]);

//tax filter to format with percentage value
angular.module('fidis-framework-filter').filter('tax', ['SessionService', function(SessionService){
	return function tax(input, digits){
		if(input !== undefined && input !== '' && input !== null){
			input = input.toString();
			if(input.indexOf('.') !== -1 && digits !== undefined){
				var temp = input.split('.');
				if(temp[1].length === 1){
					temp[1] = temp[1]+''+'0';
				}
				input = temp[0]+'.'+temp[1];
			}else if(digits !== undefined){
				input += ''+'.00';
			}

			return input.replace('.', SessionService.session.settings.number_format.decimal)+'%';

		}else{
			return 0;
		}
	};
}]);

//text filter to get a expiry date
angular.module('fidis-framework-filter').filter('expiry', ['$filter', function($filter){
	return function expiry(input, mask){
		if(input != '0000-00-00'){
			var next_check = new Date(new Date(input).setYear(new Date(input).getFullYear()+1));
			return $filter('date')(next_check, mask);
		}else{
			return '';
		}
	};
}]);

//text filter to get days remaining til expiry date
angular.module('fidis-framework-filter').filter('days_remaining', [function(){
	return function days_remaining(input){
		if(input != '0000-00-00'){
			var next_check     = new Date(new Date(input).setYear(new Date(input).getFullYear()+1));
			var remaining_days = next_check.getTime()-new Date().getTime();
			return Math.ceil(remaining_days/86400000);
		}else{
			return '';
		}
	};
}]);

//datetime filter for MySQL datetime
angular.module('fidis-framework-filter').filter('datetime', ['$filter', function($filter){
	return function datetime(input, date_format){
		//replace to convert to interpretable datetime before converting to ISO for angular default filter
		if(input != '' && input != undefined && input != null && input != '0000-00-00 00:00:00'){
			input = new Date(input.replace(/(.+) (.+)/, '$1T$2Z')).toISOString();
			return $filter('date')(input, date_format+' HH:mm:ss');
		}else{
			return '';
		}
	};
}]);

//datetime filter for MySQL date without time
angular.module('fidis-framework-filter').filter('datetime_to_date', ['$filter', function($filter){
	return function datetime_to_date(input, date_format){
		//replace to convert to interpretable datetime before converting to ISO for angular default filter
		input = new Date(input.replace(/(.+) (.+)/, '$1T$2Z')).toISOString();
		return $filter('date')(input, date_format);
	};
}]);

//datetime filter for MySQL datetime
angular.module('fidis-framework-filter').filter('date_expanded', ['$filter', function($filter){
	return function date_expanded(input, mask){
		if(input != '0000-00-00'){
			return $filter('date')(input, mask);
		}else{
			return '';
		}
	};
}]);

//date filter for MySQL date with special check for an empty date (0000-00-00)
angular.module('fidis-framework-filter').filter('limit_date', ['$filter', function($filter){
	return function limit_date(input, date_format){
		//replace to convert to interpretable datetime before converting to ISO for angular default filter
		if(input == '0000-00-00' || input == null || input == undefined){
			return '';
		}
		input = new Date(input.replace(/(.+) (.+)/, '$1T$2Z')).toISOString();
		return $filter('date')(input, date_format);
	};
}]);

angular.module('fidis-framework-filter').filter('get_json_data', [function(){
	return function get_json_data(input, keys){
		if(input != undefined && input != '' && keys != undefined && keys != '' && keys.length != 0){
			var return_value = JSON.parse(input);
			for(var i = 0; i < keys.length; i++){
				return_value = return_value[keys[i]];
			}
			return return_value;
		}else{
			return '';
		}
	};
}]);

//preparation active/inactive
angular.module('fidis-framework-filter').filter('activeInactive', ['$filter', function($filter){
	return function(input){
		return (input == 1)?$filter('translate')('ACTION_ACTIVE'):$filter('translate')('ACTION_INACTIVE');
	};
}]);
//preparation yes/no
angular.module('fidis-framework-filter').filter('yesNo', ['$filter', function($filter){
	return function(input){
		return (input == 1 || input.toUpperCase() == 'X')?$filter('translate')('ACTION_YES'):$filter('translate')('ACTION_NO');
	};
}]);
//preparation gender
angular.module('fidis-framework-filter').filter('gender', ['$filter', function($filter){
	return function(input){
		return (input == 1)?$filter('translate')('MALE'):$filter('translate')('FEMALE');
	};
}]);

//preparation wz code
angular.module('fidis-framework-filter').filter('wzCode', ['$filter', function($filter){
	return function(input){
		if(input == null || input == undefined){
			return '';
		}
		return $filter('translate')('WZ_'+input);
	};
}]);

angular.module('fidis-framework-filter').filter('crefoType', ['$filter', function($filter){
	return function(input){
		if(input == null || input == undefined){
			return '';
		}
		return $filter('translate')(input);
	};
}]);

angular.module('fidis-framework-filter').filter('lockedTemp', ['$filter', 'fidisFrameworkConfig', function($filter, fidisFrameworkConfig){
	return function(input){
		if(input == null || input == undefined){
			return '';
		}
		if(input > fidisFrameworkConfig.login_attempts){
			return $filter('translate')('ACTION_YES');
		}else{
			return $filter('translate')('ACTION_NO');
		}
	};
}]);

//preparation bytes
angular.module('fidis-framework-filter').filter('bytes', [function(){
	return function bytes(bytes, precision){
		if(bytes === 0){
			return '0 bytes';
		}
		if(bytes == 0 && isNaN(parseFloat(bytes)) || !isFinite(bytes)){
			return '-';
		}
		if(typeof precision === 'undefined'){
			precision = 1;
		}
		var units  = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
			number = Math.floor(Math.log(bytes)/Math.log(1024));
		return (bytes/Math.pow(1024, Math.floor(number))).toFixed(precision)+' '+units[number];
	};
}]);

/* global app */
angular.module('fidis-framework-filter').filter('localizeMonth', ['$interpolate', function($interpolate){
	return function localizeMonth(input){
		return input
			.replace(/JANUARY/g, $interpolate('{{ "MONTH_JANUARY"   | translate}}'))
			.replace(/FEBRUARY/g, $interpolate('{{ "MONTH_FEBRUARY"  | translate}}'))
			.replace(/MARCH/g, $interpolate('{{ "MONTH_MARCH"     | translate}}'))
			.replace(/APRIL/g, $interpolate('{{ "MONTH_APRIL"     | translate}}'))
			.replace(/MAY/g, $interpolate('{{ "MONTH_MAY"       | translate}}'))
			.replace(/JUNE/g, $interpolate('{{ "MONTH_JUNE"      | translate}}'))
			.replace(/JULY/g, $interpolate('{{ "MONTH_JULY"      | translate}}'))
			.replace(/AUGUST/g, $interpolate('{{ "MONTH_AUGUST"    | translate}}'))
			.replace(/SEPTEMBER/g, $interpolate('{{ "MONTH_SEPTEMBER" | translate}}'))
			.replace(/OCTOBER/g, $interpolate('{{ "MONTH_OCTOBER"   | translate}}'))
			.replace(/NOVEMBER/g, $interpolate('{{ "MONTH_NOVEMBER"  | translate}}'))
			.replace(/DECEMBER/g, $interpolate('{{ "MONTH_DECEMBER"  | translate}}'))
			;
	};
}]);

//translate true or false to yes and no
angular.module('fidis-framework-filter').filter('checkTranslate',['$filter', function($filter){
	return function checkTranslate(input, marker){
		if(marker === undefined){
			marker = [
				"ACTION_YES",
				"ACTION_NO"
			];
		}
		var index = 1;
		if(input === true || input === "true" || input === 1 || input === "1" || input === "X" || input === "x"){
			index = 0;
		}
		return $filter('translate')(marker[index]);
	};
}]);