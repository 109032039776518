angular.module('fidis-framework-router', [
	'fidis-framework-config',
	'oc.lazyLoad',
	'ngRoute',
	'pascalprecht.translate'
]);

angular.module('fidis-framework-router').config(['$routeProvider', '$ocLazyLoadProvider', 'fidisFrameworkConfig', function($routeProvider, $ocLazyLoadProvider, fidisFrameworkConfig){
	var path        = fidisFrameworkConfig.relpath;
	var path_client = fidisFrameworkConfig.relpath_client;
	var url_default = fidisFrameworkConfig.default_page;
	var url_login   = fidisFrameworkConfig.path_login = path_client+'/login';
	fidisFrameworkConfig.endpoints.download = path+fidisFrameworkConfig.endpoints.download;
	fidisFrameworkConfig.endpoints.session  = path+fidisFrameworkConfig.endpoints.session;
	fidisFrameworkConfig.endpoints.branding = path+fidisFrameworkConfig.endpoints.branding;

	$routeProvider
		.when(url_login, {
			templateUrl: 'login/tpl',
			resolve: {
				translations: ['$translatePartialLoader', '$translate', function($translatePartialLoader, $translate){
					$translatePartialLoader.addPart('login');
					return $translate.refresh();
				}]
			},
			controller:  'LoginCtrl'
		})
		.when('/'+path+':page', {
									templateUrl:            function(params){
										var site_type = fidisFrameworkConfig.menu.routes[params.page].type;
										var type      = '/tpl';
										switch(site_type){
											case('tbl'):
												type = '/'+site_type;
												break;
											case('upl'):
												type = '/'+site_type;
												break;
										}
										return path+params.page+type;
									},
									resolve:                {
										loadMyCtrl: ['$ocLazyLoad', '$route', '$location', '$rootScope', '$translatePartialLoader', '$translate', 'AuthenticationService', '$$animateJs', loadMyCtrl]
									},
									requiresAuthentication: true
								})
		.when('/'+path+':page/:subpage', {
											 templateUrl:            function(params){
												 var site_type = fidisFrameworkConfig.menu.routes[params.page+'_'+params.subpage].type;
												 var type      = '/tpl';
												 switch(site_type){
													 case('tbl'):
														 type = '/'+site_type;
														 break;
													 case('upl'):
														 type = '/'+site_type;
														 break;
												 }
												 return path+params.page+'/'+params.subpage+type;
											 },
											 resolve:                {
												 loadMyCtrl: ['$ocLazyLoad', '$route', '$location', '$rootScope', '$translatePartialLoader', '$translate', 'AuthenticationService', '$$animateJs', loadMyCtrl]
											 },
											 requiresAuthentication: true
										 })
		.otherwise(url_default);

	function loadMyCtrl($ocLazyLoad, $route, $location, $rootScope, $translatePartialLoader, $translate, AuthenticationService, $$animateJs){
		if($rootScope.globals.product_active !== undefined){
			$rootScope.globals.product_active.name = 'default';
		}
		var load = {
			serie: false,
			files: []
		};
		var path_rel;
		var path = path_rel = fidisFrameworkConfig.relpath;
		var params    = $route.current.params;
		var site_name = '';
		var route_name = [];
		var route;
		if(path.charAt(path.length-1) == '/'){
			path = path.slice(0, -1);
		}
		//get site name and create path
		for(var key in params){
			if(params.hasOwnProperty(key)){
				site_name = params[key];
				path += '/'+params[key];
				route_name.push(params[key]);
			}
		}
		route_name = route_name.join('_');
		route = fidisFrameworkConfig.menu.routes[route_name];
		if(route === undefined){
			$location.path(url_default);
		}else{
			//add marker key to translation object
			if(route.marker_key !== undefined){
				$translatePartialLoader.addPart(route.marker_key);
			}else{
				$translatePartialLoader.addPart(site_name);
			}
			//add additional marker keys to translation object
			if(route.marker_packs !== undefined){
				var marker_packs = route.marker_packs;
				for(var i = 0; i < marker_packs.length;i++){
					$translatePartialLoader.addPart(marker_packs[i]);
				}
			}
			//translate all marker and then load the controller
			return $translate.refresh().then(function(){
				var ctrl_file_path = '';
				if(route.type == 'ctrl'){
					ctrl_file_path = path+'/ctrl.js';
				}else if(route.type == 'upl'){
					ctrl_file_path = path_rel+'/core/upload/ctrl.js';
				}else{
					ctrl_file_path = path_rel+'/core/table/ctrl.js';
				}
				load.files.push(ctrl_file_path);
				if(route.extra_files !== undefined && route.extra_files == true){
					load.files.push(path+'/extra.js');
					load.files.push(path+'/extra.html');
				}
				return $ocLazyLoad.load(load)
								  .then(function(){
								  }, function(error){
									  //on error handling
									  AuthenticationService.logout();
									  return false;
								  });
			});
		}
	}
}]);