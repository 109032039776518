angular.module('fidis-framework-login', [
	'ngRoute',
	'ui.bootstrap.modal',
	'fidis-framework-config',
	'fidis-framework-password'
]);

angular.module('fidis-framework-login').constant('AUTH_EVENTS', {
	login:             'login',
	logout:            'logout',
	authenticated:     'authenticated',
	not_authenticated: 'not_authenticated',
	not_authorized:    'not_authorized'
});

angular.module('fidis-framework-login').run(['$rootScope', '$location', '$route', 'AuthenticationService', 'fidisFrameworkConfig', 'AUTH_EVENTS', function($rootScope, $location, $route, AuthenticationService, fidisFrameworkConfig, AUTH_EVENTS){
	$rootScope.globals = {};
	if($rootScope.userdata === undefined){
		$rootScope.userdata = {};
	}

	// check if the user has a session
	var route_changed_before = false;
	var status_checked       = false;
	AuthenticationService.status().then(function(response){
		status_checked = true;
		if(response.type === 'success'){
			if($location.path() === fidisFrameworkConfig.path_login){
				$location.path(fidisFrameworkConfig.default_page);
			}else if(route_changed_before){
				$route.reload();
			}
		}else{
			$location.path(fidisFrameworkConfig.path_login);
			$rootScope.$emit(AUTH_EVENTS.not_authenticated, response);
		}
	}).catch(function(){
		status_checked = true;
		if(AuthenticationService.is_authenticated()){
			AuthenticationService.logout();
		}else{
			$location.path(fidisFrameworkConfig.path_login);
			$rootScope.$emit(AUTH_EVENTS.not_authenticated);
		}
	});

	function build_path(data){
		var path = '';
		if(Object.keys(data.pathParams).length === 0){
			path = data.$$route.originalPath;
		}else{
			angular.forEach(data.pathParams, function(value, key){
				path += '/'+value;
			});
			path = '/'+fidisFrameworkConfig.relpath+path.substr(1);
		}
		return path;
	}

	$rootScope.$on('$routeChangeStart', function(event, next, current){
		if(next.$$route !== undefined){
			var path_next     = build_path(next);
			var is_next_login = $location.path() === fidisFrameworkConfig.path_login;
			var path_current  = path_next;
			if(current !== undefined && current.$$route !== undefined){
				path_current = build_path(current);
			}
			$rootScope.globals.next    = path_next;
			$rootScope.globals.current = path_current;

			//only redirect if the status is already checked
			if(status_checked){
				// redirect to login if the user is not authenticated but need to be
				var requried_authentication = !!(next.$$route.requiresAuthentication);
				if(requried_authentication && AuthenticationService.is_authenticated() == false){
					event.preventDefault();
					$location.path(fidisFrameworkConfig.path_login);
					$rootScope.$emit(AUTH_EVENTS.not_authenticated);
				}
				//force redirect to home if already logged in and requesting login
				if(AuthenticationService.is_authenticated() && is_next_login){
					$location.path(fidisFrameworkConfig.default_page);
				}
			}else if(!status_checked && !is_next_login){
				route_changed_before = true;
				event.preventDefault();
			}
		}
	});
}]);

/*
 * Authentication service
 */
angular.module('fidis-framework-login').service('AuthenticationService', ['$rootScope', '$http', 'SessionService', 'fidisFrameworkConfig', 'AUTH_EVENTS', function Auth($rootScope, $http, SessionService, fidisFrameworkConfig, AUTH_EVENTS){
	var path = fidisFrameworkConfig.endpoints.session;

	function return_msg(data, value){
		if(data.type === undefined){
			data.type = 'warning';
		}
		if(data.text === undefined){
			data.text = 'LOGIN_FAILED';
		}
		if(value === undefined){
			value = false;
		}

		if(value == false){
			SessionService.destroy();
		}
		SessionService.session.status = value;
		return data;
	}

	//check if a user is logged in
	function is_authenticated(){
		return SessionService.session.status;
	}

	//get the status of the user
	function status(){
		return $http.post(path, {'type': 'status'}).then(function(response){
			var data = response.data.data;
			if(response.data.type == 'success'){
				if(SessionService.session.status == false){
					SessionService.create(data.username, data.settings, data.menu, data.client);
				}
				$rootScope.authenticated = true;
				//broadcast login to the root scope
				$rootScope.$emit(AUTH_EVENTS.authenticated, response.data.data);
				return return_msg(response.data, true);
			}else{
				return return_msg(response.data);
			}
		}).catch(function(){
			return return_msg();
		});
	}

	//login function
	function login(username, password, key){
		return $http.post(path, {'type': 'login', 'msg': {username: username, password: password, analytics: key}}).then(function(response){
			var data = response.data;
			if(data.type == 'success'){
				//is now authenticated
				$rootScope.authenticated = true;
				//add session to service
				SessionService.create(data.username, data.settings, data.menu, data.client);
				//broadcast login to the root scope
				$rootScope.$emit(AUTH_EVENTS.login, response.data);
				return return_msg(response.data, true);
			}else{
				return return_msg(response.data);
			}
		}).catch(function(){
			return return_msg();
		});
	}

	//logout function
	function logout(){
		if(fidisFrameworkConfig.http_autorization != undefined && fidisFrameworkConfig.system_logout != ''){
			var language = fidisFrameworkConfig.language;
			if(SessionService.session.settings !== undefined && SessionService.session.settings !== null){
				language = SessionService.session.settings.language.split('_')[0];
			}
			if(fidisFrameworkConfig.system_logout.charAt(fidisFrameworkConfig.system_logout.length-1) !== '?'){
				fidisFrameworkConfig.system_logout += '?';
			}
			window.location = fidisFrameworkConfig.system_logout+'lang='+language;
		}else{
			return $http.post(path, {'type': 'logout'}).then(function(response){
				var data = response.data;
				if(data.type == 'success'){
					$rootScope.$emit(AUTH_EVENTS.logout);
					$rootScope.authenticated = false;
					return return_msg(response.data);
				}else{
					return return_msg(response.data, true);
				}
			}).catch(function(){
				return return_msg();
			});
		}
	}

	return {
		is_authenticated: is_authenticated,
		status:           status,
		login:            login,
		logout:           logout,
		ClearCredentials: function(){
		}
	};
}]);

/*
 * Store Session data in service
 */
angular.module('fidis-framework-login').service('SessionService', ['fidisFrameworkConfig', function SessionService(fidisFrameworkConfig){
	var SessionService     = {};
	SessionService.session = {};

	function create(username, settings, menu, client){
		SessionService.session.username = username;
		SessionService.session.settings = settings;
		SessionService.session.menu     = menu;
		SessionService.session.client   = client;
		SessionService.session.status   = true;
	}

	function destroy(){
		SessionService.session = {
			'username': null,
			'settings': {
				'date_format':   fidisFrameworkConfig.formats.date,
				'number_format': fidisFrameworkConfig.formats.number
			},
			'menu':     null,
			'client':   null,
			'status':   false
		};
	}

	//to init the session set everything to null
	destroy();

	SessionService.create  = create;
	SessionService.destroy = destroy;
	return SessionService;
}]);


/*
 * Login Controller
 */
angular.module('fidis-framework-login').controller('LoginCtrl', ['$scope', '$window', '$uibModal', 'AuthenticationService', 'PasswordService', 'fidisFrameworkConfig', function LoginCtrl($scope, $window, $uibModal, AuthenticationService, PasswordService, fidisFrameworkConfig){
	$scope.model      = {};
	$scope.submitted  = false;
	$scope.login_logo = {
		'background':      'url('+fidisFrameworkConfig.relpath+'core/branding/req?type=get_branding&cmp=login_logo) no-repeat scroll bottom',
		'background-size': 'contain'
	};

	$scope.http_autorization              = fidisFrameworkConfig.http_autorization;
	$scope.http_autorization_translations = fidisFrameworkConfig.http_autorization_translations;
	$scope.logout_redirect                = fidisFrameworkConfig.system_logout;

	$scope.login = function(){
		if($scope.form.$valid && !$scope.submitted){
			$scope.submitted = true;
			//create key
			var key          = {
				inner_x: $window.innerWidth,
				inner_y: $window.innerHeight,
				outer_x: $window.outerWidth,
				outer_y: $window.outerHeight
			};
			key              = JSON.stringify(key);
			AuthenticationService.login($scope.model.username, $scope.model.password, key).then(function(response){
				if(response.type !== 'success'){
					$scope.message   = response;
					$scope.submitted = false;
				}
			});
		}
	};

	$scope.next = function(){
		AuthenticationService.logout();
	};

	//request new password
	$scope.request_password = function(){
		PasswordService.request_password();
	};

	//close alert
	$scope.closeAlert = function(){
		$scope.message = undefined;
	};
}]);