angular.module('fidis-framework-services', ['fidis-framework-config']);
//service to handle application state changes between controllers e.g. screen resolutions
angular.module('fidis-framework-services').service('AppStateService', ['$rootScope', function AppStateService($rootScope) {
	var AppState = {};

	AppState.message = '';
	AppState.navigation = '';

	AppState.prepForBroadcast = function(msg) {
		this.message = msg;
		$rootScope.$emit('StateChange');
	};

	AppState.navigate = function(msg) {
		this.navigation = msg;
		$rootScope.$emit('StateChange');
	};

	return AppState;
}]);