angular.module('fidis-framework-permission', ['ngRoute', 'fidis-framework-config', 'fidis-framework-login']);

//delete or make it useless if the permission is not correct
angular.module('fidis-framework-permission').directive('permission', ['$compile', 'PermissionCheckService', function permission($compile, PermissionCheckService){
	return {
		restrict: 'A',
		priority: 1001,
		compile: function compile(el, attrs){
			//get element type
			var tag = el.prop('tagName');
			if(PermissionCheckService.check(attrs.permission)){
				switch(tag){
					case('INPUT'):
						//delete unnecessary attributes
						attrs.ngClick = undefined;
						attrs.ngDisabled = undefined;
						attrs.ngRequired = undefined;
						el.removeAttr("ng-click");
						el.removeAttr("ng-disabled");
						el.removeAttr("ng-required");
						//add disabled attribute
						el.attr('disabled', true);
						//add ng-model again to avoid the terminal = true
						el.attr('ng-model', attrs.ngModel);
						break;
					case('BUTTON'):
						attrs.ngIf = false;
						attrs.ngClick = undefined;
						attrs.ngDisabled = undefined;
						el.removeAttr("ng-if");
						el.removeAttr("ng-click");
						el.removeAttr("ng-disabled");
						el.html('');
						el.replaceWith(angular.element('<span class="hidden"></span>'));
						break;
					case('TD'):
						attrs.ngIf = false;
						el.removeAttr("ng-if");
						el.remove();
						break;
					case('TH'):
						//remove SmartTable directive to prevent errors
						el.removeAttr("st-search");
						el.removeAttr("st-sort");
						el.removeAttr("st-skip-natural");
						attrs.ngIf = false;
						el.removeAttr("ng-if");
						el.remove();
						break;
					case('A'):
						attrs.ngIf = false;
						attrs.ngClick = undefined;
						attrs.ngDisabled = undefined;
						el.removeAttr("ng-if");
						el.removeAttr("ng-click");
						el.removeAttr("ng-disabled");
						el.html('');
						el.replaceWith(angular.element('<span class="hidden"></span>'));
						break;
					case('SPAN'):
						attrs.ngIf = false;
						attrs.ngClick = undefined;
						attrs.ngDisabled = undefined;
						el.removeAttr("ng-if");
						el.removeAttr("ng-click");
						el.removeAttr("ng-disabled");
						el.html('');
						el.replaceWith(angular.element('<span class="hidden"></span>'));
						break;
					// var compiled = $compile(el, null, 1001);
					//
					// return function linkFn(scope) {
					// 	compiled(scope) // Link compiled element to scope
					// }
				}
			}
		}
	};
}]);

//service to handle application state changes between controllers e.g. screen resolutions
angular.module('fidis-framework-permission').service('PermissionCheckService', ['$rootScope', '$location', '$http', 'fidisFrameworkConfig', 'SessionService', function PermissionCheckService($rootScope, $location, $http, fidisFrameworkConfig, SessionService) {
	var PermissionCheck = {};

	//get current site
	PermissionCheck.retriev_location = function(set_location){
		var site = '';
		if(set_location === undefined || set_location === ''){
			var temp_path = $location.path().replace(fidisFrameworkConfig.relpath, '');
			temp_path = temp_path.replace(fidisFrameworkConfig.relpath_client, '');
			var temp_path_array = temp_path.split('/');
			if(temp_path_array.length >= 3)
				site = temp_path_array[temp_path_array.length-2]+'_'+temp_path_array[temp_path_array.length-1];
			else
				site = temp_path_array[temp_path_array.length-1];
		}else{
			site = set_location;
		}
		return site;
	};

	//there is a rare bug in the application it can happen that the permissions get delete from the rootScope
	//to prevent errors the permission get reloaded from $_SESSION
	PermissionCheck.retriev_permissions = function(site){
		var permission;
		if(site === undefined || site === '')
			site = PermissionCheck.retriev_location();
		if(SessionService.session.settings.permissions === undefined){
			$http.post(fidisFrameworkConfig.endpoints.session, {type: 'get_permissions'})
				 .then(function(response) {
					 if(response.data.type !== 'warning'){
						 SessionService.session.settings.permissions = response.data.permissions;
						 permission = response.data.permissions[site];
						 return permission
					 }else{

					 }
				 });
		}else{
			permission = SessionService.session.settings.permissions[site];
			return permission
		}
	};

	PermissionCheck.check = function(permission_element, permission){
		if(permission === undefined || permission === '')
			permission = PermissionCheck.retriev_permissions();
		return (permission === 'r' && permission_element === '') || (permission_element !== '' && permission_element === 'x' && permission !== 'x');

	};

	return PermissionCheck;
}]);