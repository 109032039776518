angular.module('fidis-framework-config', []);

angular.module('fidis-framework-config').constant('fidisFrameworkConfig', {
	formats:       {
		number: {
			decimal: '.',
			group:   ','
		},
		date:   {
			display:   'dd.MM.y',
			inputmask: 'dd.MM.y',
			parse:     'dd.MM.y',
			name:      'dd.MM.y'
		}
	},
	mobile:        '768',
	endpoints:     {
		session:  'core/session/req',
		download: 'core/download/req',
		branding: 'core/branding/req'
	},
	frame: {
		navbarHeader: 'navbarHeader/tpl',
		navbarTop: 'navbarTop/tpl',
		navbarTopMobile: 'navbarTopMobile/tpl',
		navbarSide: 'navbarSide/tpl',
		navbarFooter: 'navbarFooter/tpl'
	},
	timeout_modal: 1500,
	pwminlength: 3,
	format_number: function(value, formats){
		if(formats == undefined){
			formats = this.formats;
		}
		return value.split(formats.group).join('').replace(formats.decimal, '.');
	},
	merge_objects: function(obj1, obj2){
		for(var attr_name in obj2){
			obj1[attr_name] = obj2[attr_name];
		}
		return obj1;
	},
	sortByKey:     function(array, key, type){
		if(array !== undefined){
			return array.sort(function(a, b){
				var x,
					y;
				if(type === undefined){
					type = 'num';
				}
				if(type === 'num'){
					x = parseFloat(a[key]);
					y = parseFloat(b[key]);
				}else{
					if(a[key] !== undefined){
						x = a[key].toLowerCase();
					}else{
						x = '';
					}
					if(b[key] !== undefined){
						y = b[key].toLowerCase();
					}else{
						x = '';
					}
				}

				return ((x < y)?-1:((x > y)?1:0));
			});
		}else{
			return [];
		}
	},
	check_number:  function(input){
		return !isNaN(input-0) && input !== null && input !== "" && input !== false;
	},
	sort_dropdown: function($filter, array, key){
		var temp, array_sorted;
		if(key === undefined){
			key = 'translation';
		}
		if(array[0].value === '########'){
			temp = array[0];
			array.shift();
		}
		array_sorted = $filter('orderBy')(array, key);
		if(temp !== undefined){
			array_sorted.unshift(temp);
		}
		return array_sorted;
	},
	debounce: function(func, wait, immediate){
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}
});
