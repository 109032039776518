angular.module('fidis-framework-timeout', ['fidis-framework-config', 'fidis-framework-login', 'ui.bootstrap.modal']);

angular.module('fidis-framework-timeout').service('TimeoutService', ['$rootScope', '$uibModal', 'fidisFrameworkConfig', function SessionService($rootScope, $uibModal, fidisFrameworkConfig){
	//get the current unix timestamp
	function get_unix_timestamp(){
		return moment().unix();
	}

	//init variables
	var service        = {};
	var modal_is_open  = 0;
	var timeout_val    = parseInt(fidisFrameworkConfig.timeout);
	var unix_timestamp = get_unix_timestamp();
	var interval;

	//change the timeout value
	service.timeout_set = function(new_value){
		timeout_val = new_value;
	};

	var passed_time = 0;
	//use native JS interval to reduce the digest cycle because the $interval service will trigger the $digest cycle every time but it only need to trigger it once
	service.timeout = function(){
		interval = setInterval(function(){
			passed_time = timeout_val-(get_unix_timestamp()-unix_timestamp);
			if(passed_time <= 60 && modal_is_open == 0){
				modal_is_open = 1;
				var result    = $uibModal.open({
												   keyboard:    false,
												   backdrop:    'static',
												   templateUrl: 'timeout/tpl',
												   controller:  'timeoutCtrl'
											   }).result.then(function(){
				}, function(){
				});
			}
			if(passed_time <= 0){
				clearInterval(interval);
			}
		}, 1000);
	};

	//reset the timeout value
	service.timeout_reset = function(callback){
		unix_timestamp = get_unix_timestamp();
		modal_is_open  = 0;
	};

	//end the timeout
	service.timeout_end = function(){
		clearInterval(interval);
		modal_is_open = 0;
	};

	return service;
}]);

/*
 * Session timeout controller
 */
angular.module('fidis-framework-timeout').controller('timeoutCtrl', ['$scope', '$rootScope', '$http', '$uibModalInstance', 'AuthenticationService', 'fidisFrameworkConfig', 'SessionService',  function($scope, $rootScope, $http, $uibModalInstance, AuthenticationService, fidisFrameworkConfig, SessionService){
	$scope.hide = false;

	// refresh the timeout timer
	$scope.refresh = function(){
		var msg = {
			client:   SessionService.session.client,
			username: SessionService.session.username
		};
		$http.post(fidisFrameworkConfig.endpoints.session, {msg: msg, type: 'check_session'})
			 .then(function(response){
				 if(response.data.type === 'success'){
					 $uibModalInstance.dismiss();
				 }else{
					 $scope.logout();
				 }
			 });
	};

	//set logout function
	$scope.logout = function logout(){
		$uibModalInstance.dismiss();
		AuthenticationService.logout();
	};

	//set dismiss function
	$scope.dismiss = function dismiss(){
		$uibModalInstance.dismiss();
	};

	//get the current unix timestamp
	function get_unix_timestamp(){
		return moment().unix();
	}

	//init variables
	var timeout_val    = 60;
	var unix_timestamp = get_unix_timestamp();
	var interval;
	var passed_time = 0;
	//hide the refresh button after 60 seconds
	//use native JS interval to reduce the digest cycle because the $interval service will trigger the $digest cycle every time but it only need to trigger it once
	interval = setInterval(function(){
		passed_time = timeout_val-(get_unix_timestamp()-unix_timestamp);
		if(passed_time < 2){
			$scope.hide = true;
			clearInterval(interval);
			AuthenticationService.logout();
			$scope.$digest();
		}
	}, 1000);
}]);