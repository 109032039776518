angular.module('fidis-framework-error-handler', ['ngRoute', 'fidis-framework-login', 'fidis-framework-config', 'ui.bootstrap.modal']);

//$exceptionHandler override to prevent error message in console and open error modal window
angular.module('fidis-framework-error-handler').factory('$exceptionHandler', ['$injector', '$log', '$window', 'fidisFrameworkConfig', function($injector, $log, $window, fidisFrameworkConfig) {
	localStorage.error_modal = false;
	//check for script error that should only occur for header authentication
	function check_script_error(msg){
		if(msg == 'Script error.'){
			http_script_error = true;
			return false;
		}else{
			return true;
		}
	}
	var http_script_error = false;
	//init the error variable with the error_level
	var errors = {error_level: 'E_CLIENT'};
	//set checked variable to check if there was an javascript error or an angular error
	var checked = false;
	/*
	 * onerror works like a global try...catch for javascript
	 *
	 */
	$window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
		//clear errors variable
		errors = {error_level: 'E_CLIENT'};
		checked = true;
		//the error variable is filled with the parameters from the onerror function
		errors.javascript = {};
		errors.javascript.file = url;
		errors.javascript.line = lineNumber;
		errors.javascript.column = column;
		errors.javascript.message = errorMsg;
		//open error modal window for the user
		if(check_script_error(errorMsg))
			open_modal(errors);
	};

	return function myExceptionHandler(exception, cause) {
		if(checked == false)
			errors = {error_level: 'E_CLIENT'};
		checked = false;
		//inject rootScope
		var rootScope = $injector.get("$rootScope");
		rootScope.http_script_error = http_script_error;
		var exception_stack;
		// it is possible that the exception isn't a object but a string
		if(exception.stack !== undefined)
			exception_stack = exception.stack;
		else
			exception_stack = exception;
		//check for digest cycle error
		if(exception_stack.indexOf("$rootScope:infdig") !== -1){
			errors.infdig = true;
			errors.destination = {
				next: rootScope.globals.next,
				current: rootScope.globals.current
			};
			errors.cause = cause;
		}

		//ignore possibly unhandled rejection crap
		if(exception_stack.indexOf("Possibly unhandled rejection") == -1){
			//check if Authentication failed
			//if it fail the error code is 401
			if((exception_stack.indexOf("HTTP status: 401 Not Authenticated") === -1 && exception_stack.indexOf("Not%20Authenticated") === -1) && (exception.message != undefined && exception.message.indexOf("HTTP status: 401 Not Authenticated") === -1 && exception.message.indexOf("Not%20Authenticated") === -1)){
				var exeption_array = exception_stack.split("\n");
				errors.angular = {};
				errors.angular.complete_exception = exeption_array;
				if(!http_script_error)
					open_modal(errors);
			}else{
				if(!http_script_error)
					open_modal(401);
			}
			//check if debug is on
			//if it's on the error will be displayed in the console
			if(fidisFrameworkConfig.debug == true)
				$log.error(exception, cause);
		}
	};
	//function to open the server error modal window so the user know there was an error
	function open_modal(errors){
		//check for error_modal in the localStorage to prevent multiple modal windows
		if(localStorage.error_modal != "true"){
			localStorage.error_modal = true;
			var uibModal = $injector.get("$uibModal");
			//close all open modal windows
			var uibModalStack = $injector.get("$uibModalStack");
			uibModalStack.dismissAll();
			var modalInstance = uibModal.open({
												  animation: true,
												  keyboard : false,
												  backdrop : 'static',
												  templateUrl: 'serverErrorModal/tpl',
												  controller: 'serverErrorModalCtrl',
												  resolve: {
													  status: function() {
														  return errors;
													  }
												  }
											  }).result.then(function() {
			}, function(){
			});
		}
	}
}]);

angular.module('fidis-framework-error-handler').controller('serverErrorModalCtrl', ['$scope', '$rootScope', '$http', '$uibModalInstance', '$location', 'AuthenticationService', 'status', 'fidisFrameworkConfig', function($scope, $rootScope, $http, $uibModalInstance, $location, AuthenticationService, status, fidisFrameworkConfig){
	//set error code handle client error
	if((typeof status === 'string' || typeof status === 'number') && status != -1){
		$scope.status = status;
	}else{
		if(status.infdig !== undefined && status.infdig == true){
			$scope.status = 'infdig';
			if($rootScope.globals.next === fidisFrameworkConfig.default_page){
				$scope.logout();
			}else{
				$location.path(fidisFrameworkConfig.default_page);
			}
		}else{
			$scope.status = 500;
		}


		$http.post(fidisFrameworkConfig.endpoints.session, {type: 'log_client_error', msg: status, location: $location.path().slice(1)})
			 .then(function(response){
			 });
	}
	$scope.ng_if          = {};
	//set ng if condition for redirect button
	$scope.ng_if.redirect = $scope.status != -1 && $rootScope.globals.next !== fidisFrameworkConfig.path_login && $scope.status !== 'infdig' && $scope.status != '401';
	//set ng if condition for logout button
	$scope.ng_if.logout   = ($rootScope.globals.next !== fidisFrameworkConfig.path_login && $scope.status !== 'infdig' && $scope.status != '401') && fidisFrameworkConfig.http_autorization == undefined;
	//set ng if condition for ok button
	$scope.ng_if.ok       = $scope.status === 'infdig' || ($scope.status == 401 && fidisFrameworkConfig.http_autorization == undefined);


	//redirect to home
	$scope.redirect = function(){
		$uibModalInstance.close();
		$location.path(fidisFrameworkConfig.default_page);
		localStorage.error_modal = false;
	};

	//redirect to home
	$scope.ok = function(){
		$uibModalInstance.close();
		localStorage.error_modal = false;
	};

	//logout
	$scope.logout = function(close){
		if(close === true){
			$uibModalInstance.close();
		}
		AuthenticationService.logout();
		localStorage.error_modal = false;
	};
}]);