angular.module('fidis-framework', [
	'fidis-framework-config',
	'fidis-framework-factories',
	'fidis-framework-services',
	'fidis-framework-directives',
	'fidis-framework-filter',
	'fidis-framework-router',
	'fidis-framework-error-handler',
	'fidis-framework-timeout',
	'fidis-framework-password',
	'fidis-framework-login',
	'fidis-framework-frame',
	'fidis-framework-modal-info',
	'fidis-framework-permission',
	'fidis-framework-smart-table',
	'fidis-framework-templates',
	'fidis-framework-checkbox',
	'mw-error-message',
	'smart-table',
	'angular-loading-bar',
	'pascalprecht.translate'
]);

angular.module('fidis-framework').config(['$locationProvider', '$httpProvider', '$ocLazyLoadProvider', '$translateProvider', '$compileProvider', '$qProvider', 'cfpLoadingBarProvider', 'mwConfig', '$uibTooltipProvider', 'stConfig', 'fidisFrameworkConfig', function($locationProvider, $httpProvider, $ocLazyLoadProvider, $translateProvider, $compileProvider, $qProvider, cfpLoadingBarProvider, mwConfig, $uibTooltipProvider, stConfig, fidisFrameworkConfig){
	var debug = fidisFrameworkConfig.debug;
	$ocLazyLoadProvider.config({
								   debug: false//debug
							   });
	$httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	$httpProvider.defaults.headers.post['Content-Type']       = 'application/x-www-form-urlencoded; charset=UTF-8';
	$httpProvider.interceptors.push('httpInterceptor');
	$httpProvider.useApplyAsync(true);

	$locationProvider.html5Mode(true);

	/*
	 * performance boost for productive https://docs.angularjs.org/guide/production#disabling-debug-data makes batarang and ng-inspector useless
	 */
	$compileProvider.debugInfoEnabled(debug);
	$compileProvider.commentDirectivesEnabled(debug);
	// stop throwing errors on unhandled promises rejections
	$qProvider.errorOnUnhandledRejections(debug);


	/*
	 * i18n
	 */
	//sanitization strategy
	$translateProvider.useSanitizeValueStrategy(null);
	//set translation url
	$translateProvider.useLoader('$translatePartialLoader', {
		urlTemplate: 'lang-{part}-{lang}'
	});
	//default language
	$translateProvider.preferredLanguage(fidisFrameworkConfig.language);
	//cant use $translatePartialLoaderProvider.addPart('init'); because it will break the addPart from $translatePartialLoader inside the router

	/*
	 * loading-bar config
	 */
	cfpLoadingBarProvider.includeSpinner = false;
	cfpLoadingBarProvider.includeBar     = true;
	cfpLoadingBarProvider.parentSelector = '#loading_bar_container';

	/*
	 * error message config
	 */
	mwConfig.success   = false;
	mwConfig.icon      = true;
	mwConfig.tooltip   = false;
	mwConfig.translate = true;

	/*
	 * set tooltip placement to body to prevent tooltip cut from higher container
	 */
	$uibTooltipProvider.options(
		{
			'appendToBody': 'true'
		}
	);

	/*
	 * fidis-smart-table config
	 */
	stConfig.endpoint   = '/req';
	stConfig.translate  = true;
	stConfig.tableCache = fidisFrameworkConfig.table_cache;

}]);

angular.module('fidis-framework').run(['$rootScope', '$window', '$templateCache', '$timeout', '$location', '$translate', '$translatePartialLoader', '$uibModal', 'SmartTable_pipeline', 'TimeoutService', 'fidisFrameworkConfig', 'AUTH_EVENTS', 'SessionService', 'PasswordService', function FidisFrameworkRun($rootScope, $window, $templateCache, $timeout, $location, $translate, $translatePartialLoader, $uibModal, SmartTable_pipeline, TimeoutService, fidisFrameworkConfig, AUTH_EVENTS, SessionService, PasswordService){
	//handle loading bar
	$rootScope.loading = {
		value: true,
		name:  ''
	};
	$rootScope.$on('cfpLoadingBar:started', function(event){
		$rootScope.loading.value = true;
	});
	$rootScope.$on('cfpLoadingBar:completed', function(event){
		$rootScope.loading.value = false;
		$rootScope.loading.name  = '';
	});

	function authenticated(data){
		TimeoutService.timeout();
		$translate.use(data.settings.language).then(function(){
			load_translate_init();
			if($rootScope.globals.next != '' && $rootScope.globals.next != fidisFrameworkConfig.path_login){
				$location.path($rootScope.globals.next);
			}else{
				$location.path(fidisFrameworkConfig.default_page);
			}
		});
		$rootScope.globals.language = data.settings.language;
		$rootScope.userdata         = {
			username:      data.username,
			settings:      data.settings,
			menu:          data.menu,
			client:        data.client,
			authenticated: true
		};
		if(data.change_pw == true){
			PasswordService.change_pw();
		}
		fidisFrameworkConfig.menu = data.menu;
	}

	function load_translate_init(){
		$translatePartialLoader.addPart('init');
		$translate.refresh();
	}

	//watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.login, function(event, data){
		authenticated(data);
	});
	// //watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.authenticated, function(event, data){
		authenticated(data);
	});

	// //watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.not_authenticated, function(event, data){
		load_translate_init();
	});

	//watch for logout to do necessary stuff like end the timeout
	$rootScope.$on(AUTH_EVENTS.logout, function(event){
		TimeoutService.timeout_end();
		SmartTable_pipeline.reset();
		//delete template cache
		angular.forEach(fidisFrameworkConfig.menu.side, function(value, key){
			$templateCache.remove(value.link+'/tbl');
			$templateCache.remove(value.link+'/tpl');
			if(value['subpages'] !== undefined){
				angular.forEach(value['subpages'], function(subvalue, subkey){
					$templateCache.remove(subvalue.link+'/tbl');
					$templateCache.remove(subvalue.link+'/tpl');
				});
			}
		});
		$location.path(fidisFrameworkConfig.path_login);
		$rootScope.userdata = {};
	});

	//emit a resize event to the rootScope
	var window_size   = $window.innerWidth;
	$rootScope.mobile = window_size <= fidisFrameworkConfig.mobile;
	//define debounce resize function
	var debounce_resize = fidisFrameworkConfig.debounce(function(){
		window_size       = $window.innerWidth;
		$rootScope.mobile = window_size <= fidisFrameworkConfig.mobile;
		$rootScope.$emit('window_resize', window_size);
		//trigger digest cycle cause the resize event is out of angular
		$rootScope.$digest();
	}, 100);
	//bind debounce resize function
	angular.element($window).bind('resize', debounce_resize);
}]);


angular.module('fidis-framework').controller('BaseMainCtrl', ['$scope', '$rootScope', '$timeout', 'AppStateService', 'fidisFrameworkConfig', 'TimeoutService', 'SessionService', 'AUTH_EVENTS', function($scope, $rootScope, $timeout, AppStateService, fidisFrameworkConfig, TimeoutService, SessionService, AUTH_EVENTS){
	$scope.default_page           = fidisFrameworkConfig.default_page.substr(1);
	$scope.relpath = fidisFrameworkConfig.relpath;
	$scope.MainCtrl = {};
	$scope.MainCtrl.format = {};
	$rootScope.$on(AUTH_EVENTS.login, function(event, data){
		$scope.MainCtrl.permission_group = SessionService.session.settings.permission_group;
		$scope.MainCtrl.format.date = SessionService.session.settings.date_format;
		$scope.MainCtrl.format.number = SessionService.session.settings.number_format;
	});
	//watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.authenticated, function(event, data){
		$scope.MainCtrl.permission_group = SessionService.session.settings.permission_group;
		$scope.MainCtrl.format.date = SessionService.session.settings.date_format;
		$scope.MainCtrl.format.number = SessionService.session.settings.number_format;
	});

	//watch for route change so that the mobile menu will be closed on route change
	$rootScope.$on('$routeChangeStart', function(event, data){
		TimeoutService.timeout_reset();
		if($rootScope.mobile == true){
			//TODO get rid of the dom manipulation
			$timeout(function(){
				var el = document.getElementById('mobile_top_menu_switch');
				var navbar = document.getElementById('navbar');
				if(navbar !== undefined && navbar !== null){
					if([].slice.call(navbar.classList).indexOf('in') !== -1 && el !== undefined && el !== null){
						angular.element(el).trigger('click');
					}
				}

			}, 0);
		}
	});

	//registered a click on the main-content-page
	$scope.close_subpage_mainctrl = function(){
		$rootScope.$emit('collapse_sidebar');
	};

	$scope.change_device = function(){
		console.log('resize event not defined');
	};

	$rootScope.$on('window_resize', function(event, size){
		$scope.change_device(size);
	});

	//watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.login, function(event, data){
		AppStateService.prepForBroadcast('expanded');
	});
	//watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.authenticated, function(event, data){
		AppStateService.prepForBroadcast('expanded');
	});
}]);


angular.module('fidis-framework').controller('BaseMenuCtrl', ['$scope', '$rootScope', '$timeout', 'SessionService', 'AUTH_EVENTS', function($scope, $rootScope, $timeout, SessionService, AUTH_EVENTS){
	$scope.menu = SessionService.session.menu;
	//watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.login, function(event, data){
		$scope.menu = SessionService.session.menu;
	});
	//watch for login to do necessary stuff like start the timeout
	$rootScope.$on(AUTH_EVENTS.authenticated, function(event, data){
		$scope.menu = SessionService.session.menu;
	});

	var collapse = false;
	//close or open a sidebar menu
	$scope.collapse = function(){
		if(this.link === undefined || (this.link !== $rootScope.globals.next)){
			if(this.page.isCollapsed === undefined){
				this.page.isCollapsed = false;
			}
			var temp = this.page.isCollapsed;
			for(var i = 0; i < $scope.menu.side.length; i++){
				$scope.menu.side[i].isCollapsed = false;
			}
			if(this.page.subpages !== undefined){
				this.page.isCollapsed = collapse= !temp;
			}
		}
	};

	//close all sidebar menus when you click on the navbars but only if the function collapse isn't triggered
	$scope.close_subpage = function (){
		if(collapse != true){
			$rootScope.$emit('collapse_sidebar');
		}
		collapse = false;
	};

	//get the registered click on the main-content-page and close all sidebar menus
	$rootScope.$on('collapse_sidebar', function(){
		if($rootScope.menu_state !== 'expanded' && $scope.menu !== null){
			angular.forEach($scope.menu.side, function(value, key) {
				$scope.menu.side[key].isCollapsed = false;
			});
		}
	});
}]);

angular.module('fidis-framework').controller('MenuSideCtrl', ['$scope', '$rootScope', '$controller', 'fidisFrameworkConfig', 'SessionService', function($scope, $rootScope, $controller, fidisFrameworkConfig, SessionService){
	// Inherit from Base Controller
	angular.extend($scope, $controller('BaseMenuCtrl', {
		$scope: $scope
	}));
}]);

angular.module('fidis-framework').controller('MenuHeaderCtrl', ['$scope', '$rootScope', '$controller', '$http', '$location', '$timeout', '$translate', 'fidisFrameworkConfig', 'AppStateService', 'AuthenticationService', 'SessionService', function($scope, $rootScope, $controller, $http, $location, $timeout, $translate, fidisFrameworkConfig, AppStateService, AuthenticationService, SessionService){
	// Inherit from Base Controller
	angular.extend($scope, $controller('BaseMenuCtrl', {
		$scope: $scope
	}));

	$scope.help_url = fidisFrameworkConfig.help_link;

	//TODO change logo on language change
	var set_logo = function(msg){
		if(msg === 'expanded'){
			$scope.mobile_logo = $scope.logo_modifier_style = {
				'background':      'url('+fidisFrameworkConfig.endpoints.branding+'?type=get_branding&cmp=brand_logo) no-repeat scroll center',
				'background-size': 'contain'
			};
		}else if(msg === 'collapsed'){
			$scope.logo_modifier_style = {
				'background':      'url('+fidisFrameworkConfig.endpoints.branding+'?type=get_branding&cmp=brand_icon) no-repeat scroll center',
				'background-size': 'contain'
			};
		}

	};
	//watch for change in the appStateService
	$rootScope.$on('StateChange', function(){
		set_logo(AppStateService.message);
	});
	//init the logos
	set_logo(AppStateService.message);

	$scope.toggleSidebar = function () {
		$rootScope.menu_state = ($scope.menu_state === 'expanded')? 'collapsed' : 'expanded';
		AppStateService.prepForBroadcast($rootScope.menu_state);
	};

	$scope.changeLang = function(key){
		//switch language
		$translate.use(key).then(function(key){
			//use refresh in addition because of the site change language change bug where if the user changes the site and then change the language the marker are shown
			$translate.refresh().then(function(){
				$rootScope.globals.language = key;
				//apply language switch
				SessionService.session.settings.language = key;
				$http.post(fidisFrameworkConfig.endpoints.session, {type: 'change_language', msg: key})
					 .then(function(response) {

					 });
			});

		}, function(key){
			//console.log("unable so switch to: " + key);
		});
	};

	$scope.action = function(action){
		//request session logout on server and delete local data afterwards
		if(action === 'logout'){
			AuthenticationService.logout();
		}
	};
}]);