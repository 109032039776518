angular.module('fidis-framework-directives', ['fidis-framework-config', 'fidis-framework-login']);

/*
 *
 * Input Masks
 *
 */
angular.module('fidis-framework-directives').directive('decimalMask', ['fidisFrameworkConfig', 'SessionService', function(fidisFrameworkConfig, SessionService){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function decimalMask(scope, el, attrs){
			var formats = {
				decimal: SessionService.session.settings.number_format.decimal,
				group: SessionService.session.settings.number_format.group
			};

			var counter = 0;
			var suffix = '';
			if(attrs.decimalMaskSuffix != undefined)
				suffix = ' '+attrs.decimalMaskSuffix;
			var placeholder = '';
			var digits_optional = true;
			if(attrs.decimalMask == "true"){
				placeholder = '0'+formats.decimal+'00';
				digits_optional = false;
			}
			var autounmask = true;
			if(attrs.decimalMaskAutoUnmask != undefined){
				autounmask = attrs.decimalMaskAutoUnmask;
			}
			$(el).inputmask({
								alias: 'decimal',
								groupSeparator: formats.group,
								autoGroup: true,
								radixPoint: formats.decimal,
								digits: 2,
								digitsOptional: digits_optional,
								suffix: suffix,
								placeholder: placeholder,
								allowMinus: true,
								autoUnmask: autounmask,
								rightAlign: false,
								onBeforeMask: function (value, opts) {
									value = value.split(opts.groupSeparator).join(opts.radixPoint);
									return value;
								},
								onUnMask: function(maskedValue, unmaskedValue, opts) {
									//strip away any markup to return an interpretable number
									unmaskedValue = fidisFrameworkConfig.format_number(unmaskedValue, formats);
									return unmaskedValue;
								},
								/*
								 * trigger input event to let smarttable know that the input has changed in case of cleared search parameter
								 */
								oncleared: function(){
									$(el).trigger('input');
								}
							});
			$(el).on('change', function(){
				scope.$eval(attrs.ngModel + "='" + $(el).inputmask('unmaskedvalue') + "'");
			});
		}
	};
}]);

angular.module('fidis-framework-directives').directive('integerMask', ['SessionService', function(SessionService){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function integerMask(scope, el, attrs, ctrl){
			var formats = {
				decimal: SessionService.session.settings.number_format.decimal,
				group: SessionService.session.settings.number_format.group
			};
			$(el).inputmask({
								alias: 'integer',
								groupSeparator: formats.group,
								rightAlign: false,
								min: (attrs.min)?attrs.min:null,
								max: (attrs.max)?attrs.max:null
							});
			//fix max value bug that sometime the model won't be updated
			el.bind('blur', function() {
				ctrl.$setViewValue(el.val());
				ctrl.$render();
			});
		}
	};
}]);

angular.module('fidis-framework-directives').directive('numericMask', ['fidisFrameworkConfig', 'SessionService', function(fidisFrameworkConfig, SessionService){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function numericMask(scope, el, attrs, model){
			var formats = {
				decimal: SessionService.session.settings.number_format.decimal,
				group: SessionService.session.settings.number_format.group
			};
			var counter = 0;
			//$(el).inputmask(scope.$eval(attrs.inputMask));
			$(el).inputmask({
								alias: 'decimal',
								groupSeparator: formats.group,
								autoGroup: true,
								radixPoint: formats.decimal,
								digits: 0,
								digitsOptional: true,
								suffix: '',
								placeholder: '',
								allowMinus: false,
								autoUnmask: true,
								rightAlign: false,
								onBeforeMask: function (value, opts) {
									value = value.split(opts.groupSeparator).join(opts.radixPoint);
									return value;
								},
								onUnMask: function(maskedValue, unmaskedValue, opts) {
									//strip away any markup to return an interpretable number
									unmaskedValue = fidisFrameworkConfig.format_number(unmaskedValue, formats);
									return unmaskedValue;
								},
								oncleared: function(){
									$(el).trigger('input');
								}
							});
			$(el).on('change', function(){
				scope.$eval(attrs.ngModel + "='" + $(el).inputmask('unmaskedvalue') + "'");
			});
		}
	};
}]);

angular.module('fidis-framework-directives').directive('percentMask', ['fidisFrameworkConfig', 'SessionService',  function(fidisFrameworkConfig, SessionService){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function percentMask(scope, el, attrs, model){
			var formats = {
				decimal: SessionService.session.settings.number_format.decimal,
				group: SessionService.session.settings.number_format.group
			};
			$(el).inputmask({
								alias: 'decimal',
								groupSeparator: formats.group,
								autoGroup: true,
								radixPoint: formats.decimal,
								digits: 2,
								digitsOptional: true,
								suffix: '%',
								placeholder: '',
								allowMinus: false,
								autoUnmask: true,
								rightAlign: false,
								max: 100,
								/*
								 * trigger input event to let smarttable know that the input has changed in case of cleared search parameter
								 */
								oncleared: function(){
									$(el).trigger('input');
								}
							});
			$(el).on('change', function(){
				var value = el.val();
				if(value !== undefined){
					value =  fidisFrameworkConfig.format_number(value, formats);
				}
				scope.$eval(attrs.ngModel + "='" + value + "'");
			});
		}
	};
}]);

//this required maxlength as attribute
angular.module('fidis-framework-directives').directive('letterMask', ['$timeout',  function($timeout){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function letterMask(scope, el, attrs, model){
			var mask_length = 0;
			var letters = '';
			if(attrs.maxlength == undefined){
				mask_length = 30;
			}else{
				mask_length = attrs.maxlength;
			}
			for(var i = 0; i<mask_length; i++){
				letters += 'a'
			}

			$(el).inputmask({
								mask: letters,
								placeholder:''
							});
			$(el).on('change', function(){
				if(el.val() == '0'){
					$timeout(function() {
						model.$setValidity('required', false);
					}, 0);
				}else{
					scope.$eval(attrs.ngModel + "='" + el.val() + "'");
					// or scope[attrs.ngModel] = el.val() if your expression doesn't contain dot.
					model.$setValidity('required', true);
				}
			});
		}
	};
}]);


/*
*
* Validation
*
*/
angular.module('fidis-framework-directives').directive('birthdayMask', [function(){
	return {
		require: 'ngModel',
		restrict: 'A',
		link: function(scope, el, attrs, ctrl){
			$(el).inputmask({
								mask: "d.m.y",
								placeholder: "_",
								clearIncomplete: true,
								onUnMask: function(maskedValue, unmaskedValue) {
									//strip away any markup to return an interpretable number
									//unmaskedValue = unmaskedValue.split('.').join('').replace(',','.');
									// var temp = maskedValue.split('.');
									// unmaskedValue = temp[2]+"-"+temp[1]+"-"+temp[0];
									// return unmaskedValue;
								}
							});
			$(el).on('change', function(){
				scope.$eval(attrs.ngModel + "='" + el.val() + "'");
			});

			ctrl.$validators.birthdayMask = function(modelValue) {
				if(modelValue != undefined && modelValue.indexOf("_") == -1){
					var date_pieces = modelValue.split(".");
					var input_date_object = new Date(date_pieces[2],date_pieces[1]-1,date_pieces[0]);

					var adult_birth_date = new Date();
					adult_birth_date.setFullYear(adult_birth_date.getFullYear()-18);
					return ctrl.$isEmpty(modelValue) || input_date_object < adult_birth_date;
				}
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('uppercaseNeededMask', [function(){
	// create the regex obj.
	var regex = /[A-Z]/;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function uppercaseNeededMask(scope, elem, attr, ctrl) {
			ctrl.$validators.uppercaseNeededMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('digitNeededMask', [function(){
	// create the regex obj.
	var regex = /[0-9]/;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function digitNeededMask(scope, elem, attr, ctrl) {
			ctrl.$validators.digitNeededMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('specialCharNeededMask', [function(){
	// create the regex obj.
	var regex = /[!"$%&/()=?@#<>§.:,;'*_^°|`´~{}+\-\]\[\\]/;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function specialCharNeededMask(scope, elem, attr, ctrl) {
			ctrl.$validators.specialCharNeededMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('sameValue', ['$parse', function($parse){
	return {
		restrict: 'A',
		require: 'ngModel',
		scope:{
			sameValue: '='
		},
		link: function sameValue(scope, elem, attr, ctrl) {
			//set allowInvalid to true too fix the bug that the model value isn't updated correctly after the values are the same
			ctrl.$options.$$options.allowInvalid = true;
			scope.$watch('sameValue',function(value_new){
				if(value_new != scope.sameValue){
					ctrl.$setValidity('sameValue', false);
				}else{
					ctrl.$setValidity('sameValue', true);
				}
			});
			ctrl.$validators.sameValue = function(modelValue) {
				if(scope.sameValue != '' && scope.sameValue != undefined){
					return ctrl.$isEmpty(modelValue) || scope.sameValue == modelValue;
				}else{
					return ctrl.$isEmpty(modelValue) || true;
				}
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('urlMask', [function(){
	// create the regex obj.
	var regex = /.+\.[a-zA-Z]{2,}(\/)?$/;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function urlMask(scope, elem, attr, ctrl) {
			ctrl.$validators.urlMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('email', [function(){
	var regex = /\S+@\S+\.\S+/;
	return {
		require: 'ngModel',
		restrict: '',
		link: function email(scope, elm, attrs, ctrl) {
			// only apply the validator if ngModel is present and Angular has added the email validator
			if (ctrl && ctrl.$validators.email) {
				// this will overwrite the default Angular email validator
				ctrl.$validators.email = function(modelValue) {
					return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
				};
			}
		}
	};
}]);


angular.module('fidis-framework-directives').directive('greaterZeroMask', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function greaterZeroMask(scope, el, attrs, ctrl){
			$(el).inputmask({
								alias: 'integer',
								rightAlign: false
							});
			ctrl.$validators.greaterZeroMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || modelValue > 0;
			};
		}

	};
}]);

angular.module('fidis-framework-directives').directive('greaterEqualThan', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function greaterEqualThan(scope, el, attr, ctrl){
			var modelValue_last;
			ctrl.$validators.greaterEqualThan = function(modelValue) {
				modelValue_last = modelValue;
				if(attr.greaterEqualThan !== '' && attr.greaterEqualThan !== undefined){
					return ctrl.$isEmpty(modelValue) || modelValue >= parseFloat(attr.greaterEqualThan);
				}else{
					return ctrl.$isEmpty(modelValue) || true;
				}
			};
			attr.$observe('greaterEqualThan', function(attrValue) {
				var check;
				if(attrValue !== '' && attrValue !== undefined){
					check = ctrl.$isEmpty(modelValue_last) || modelValue_last >= parseFloat(attrValue);
				}else{
					check = ctrl.$isEmpty(modelValue_last) || true;
				}
				ctrl.$setValidity('greaterEqualThan', check);
			});
		}
	};
}]);

angular.module('fidis-framework-directives').directive('smallerEqualThan', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function smallerEqualThan(scope, el, attr, ctrl){
			var modelValue_last;
			ctrl.$validators.smallerEqualThan = function(modelValue) {
				modelValue_last = modelValue;
				if(attr.smallerEqualThan !== '' && attr.smallerEqualThan !== undefined){
					return ctrl.$isEmpty(modelValue) || modelValue <= parseFloat(attr.smallerEqualThan);
				}else{
					return ctrl.$isEmpty(modelValue) || true;
				}
			};
			attr.$observe('smallerEqualThan', function(attrValue) {
				var check;
				if(attrValue !== '' && attrValue !== undefined){
					check = ctrl.$isEmpty(modelValue_last) || modelValue_last <= parseFloat(attrValue);
				}else{
					check = ctrl.$isEmpty(modelValue_last) || true;
				}
				ctrl.$setValidity('smallerEqualThan', check);
			});
		}
	};
}]);

//validate if input is in port range
angular.module('fidis-framework-directives').directive('portCheckMask', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function portCheckMask(scope, el, attrs, ctrl){
			$(el).inputmask({
								alias: 'integer',
								rightAlign: false
							});
			ctrl.$validators.portCheckMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || modelValue < 65535;
			};
		}
	};
}]);

//validate if input is in session timeout range
angular.module('fidis-framework-directives').directive('sessionMask', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function sessionMask(scope, el, attrs, ctrl){
			$(el).inputmask({
								alias: 'integer',
								rightAlign: false
							});
			ctrl.$validators.sessionMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || modelValue > 120;
			};
		}

	};
}]);

//input need to be higher than specific value
angular.module('fidis-framework-directives').directive('minLimitMask', ['fidisFrameworkConfig', function(fidisFrameworkConfig){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function minLimitMask(scope, el, attrs, ctrl){
			ctrl.$validators.minLimitMask = function(modelValue){
				var unmaskedvalue = fidisFrameworkConfig.format_number(attrs.minLimitMask);
				return ctrl.$isEmpty(modelValue) || modelValue >= parseInt(unmaskedvalue);
			}
		}

	};
}]);

//validate for zip code
angular.module('fidis-framework-directives').directive('zipCode', [function(){
	var regex = /^(?!01000|99999)(0[1-9][0-9]{3}|[1-9][0-9]{4})$/;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function zipCode(scope, elem, attr, ctrl) {
			ctrl.$validators.zipCode = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
			};
		}
	};
}]);

//validate for leading zero
angular.module('fidis-framework-directives').directive('leadingZeroMask', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function leadingZeroMask(scope, el, attrs, ctrl){
			ctrl.$validators.leadingZeroMask = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || $(el).val().substr(0, 1) != '0';
			};
		}

	};
}]);

//validate VAT id and create own a input mask
angular.module('fidis-framework-directives').directive('vatId',[function(){
	// create the regex obj.
	var regex = /^[A-Z]{2}[0-9]+$/;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function vatId(scope, elem, attr, ctrl) {
			elem[0].addEventListener("input", function(test){
				//convert the letters to upper case and delete all special char's
				var val = this.value.toUpperCase().replace(/[^A-Z0-9]/g, "");
				if (val !== this.value)
					this.value = val;
			});

			ctrl.$validators.vatId = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || regex.test(modelValue);
			};
		}
	};
}]);

//input need to be multiple of 1000
angular.module('fidis-framework-directives').directive('thousands',[function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function thousands(scope, elem, attr, ctrl) {
			ctrl.$validators.thousands = function(modelValue) {
				return ctrl.$isEmpty(modelValue) || (modelValue/1000)%1 === 0;
			};
		}
	};
}]);

angular.module('fidis-framework-directives').directive('repeatAscValues', [function(){
	var previous_value = '';
	var next_value = '';
	var temp_array = [];
	var return_value = true;
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function repeatAscValues(scope, el, attrs, ctrl){
			ctrl.$validators.repeatAscValues = function(modelValue) {
				if(ctrl.$isEmpty(modelValue))
					return false;
				return_value = true;
				if(attrs.repeatAscValues.indexOf(',') != -1){
					temp_array = attrs.repeatAscValues.split(',');
					if(temp_array[0] != '')
						previous_value = parseFloat(temp_array[0]);
					else
						previous_value = temp_array[0];
					if(temp_array[1] != '')
						next_value = parseFloat(temp_array[1]);
					else
						next_value = temp_array[1];

					if(previous_value == '' && next_value == ''){
						return_value = false;
					}else if(previous_value == '' && next_value != ''){
						if(modelValue >= next_value)
							return_value = false;
					}else if(previous_value != '' && next_value == ''){
						if(modelValue <= previous_value)
							return_value = false;
					}else if(previous_value != '' && next_value != ''){
						if(modelValue <= previous_value || modelValue >= next_value)
							return_value = false;
					}
				}else{
					temp_array = [];
					next_value = '';
					if(attrs.repeatAscValues != '')
						previous_value = parseFloat(attrs.repeatAscValues);
					else
						previous_value = '';

					if(modelValue <= previous_value)
						return_value = false;
				}
				return return_value;
			};
		}
	};
}]);

//check if dropdown is on empty or on default where default means empty
angular.module('fidis-framework-directives').directive('dropdownDefaultCheck', [function(){
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function dropdownDefaultCheck(scope, elem, attr, ctrl) {
			ctrl.$validators.required = function(modelValue){
				return attr.required === false || (modelValue !== undefined && ((modelValue.value === undefined && modelValue !== '########' && modelValue !== '') || (modelValue.value !== undefined && modelValue.value !== '########' && modelValue.value !== '')));
			};
		}
	};
}]);

/*
 *
 * HTML compile
 *
 */

//create download button for tables
angular.module('fidis-framework-directives').directive('downloadButton', ['$compile', function downloadButton($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				pre: function preLink(scope, el, attrs, ctrl){
					//delete array notation
					attrs.downloadButton = attrs.downloadButton.slice(0, -1);
					attrs.downloadButton = attrs.downloadButton.slice(1);
					var array = attrs.downloadButton.split(',');

					var el_new = '';
					//append html
					if(array[3] !== undefined && array[3] == "true"){
						el_new = '<a ng-if="'+array[2]+' == 1 || '+array[2]+' == 2" ng-click="download('+array[0]+', '+array[1]+')"><i class="fa fa-download dl-icon"></i></a>';
					}else{
						el_new = '<a ng-click="download('+array[0]+', '+array[1]+')"><i class="fa fa-download dl-icon"></i></a>';
					}

					//remove directive to prevent infinite loop
					el.removeAttr("download-button");
					//delete current data
					el.empty();
					//append new element
					el.append(el_new);

					$compile(el)(scope);
				}
			};
		}
	};
}]);

//create delete button for tables
angular.module('fidis-framework-directives').directive('deleteButton', ['$compile', function deleteButton($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				post: function preLink(scope, el, attrs, ctrl){
					var listener = scope.$watch(attrs.deleteButton, function(value){
						//destroy watcher
						listener();
						var ngif_check = true;
						if (value.length > 1) {
							ngif_check = value[1];
						}
						el.empty();
						el.append('<a ng-if="'+ngif_check+'" ng-click="delete_item('+value[0]+')"><i class="fa fa-remove dl-icon"></i></a>');
						//remove directive to prevent infinite loop
						el.removeAttr("delete-button");
						$compile(el)(scope);
					});
				}
			};
		}
	};
}]);

//create edit button for tables
angular.module('fidis-framework-directives').directive('editButton', ['$compile', function editButton($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				post: function preLink(scope, el, attrs, ctrl){
					//delete array notation
					attrs.editButton = attrs.editButton.slice(0, -1);
					attrs.editButton = attrs.editButton.slice(1);

					//append html
					var el_new = '<a ng-click="edit('+attrs.editButton+')"><i class="fa fa-pencil dl-icon"></i></a>';

					//remove directive to prevent infinite loop
					el.removeAttr("edit-button");
					//delete current data
					el.empty();
					//append new element
					el.append(el_new);

					$compile(el)(scope);
				}
			};
		}
	};
}]);

//create copy button for tables
angular.module('fidis-framework-directives').directive('copyButton', ['$compile', function copyButton($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				post: function preLink(scope, el, attrs, ctrl){
					//delete array notation
					attrs.copyButton = attrs.copyButton.slice(0, -1);
					attrs.copyButton = attrs.copyButton.slice(1);

					//append html
					var el_new = '<a ng-click="copy('+attrs.copyButton+')"><i class="fa fa-clone"></i></a>';

					//remove directive to prevent infinite loop
					el.removeAttr("copy-button");
					//delete current data
					el.empty();
					//append new element
					el.append(el_new);

					$compile(el)(scope);
				}
			};
		}
	};
}]);


//create detail button for tables
angular.module('fidis-framework-directives').directive('detailButton', ['$compile', function detailButton($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				post: function preLink(scope, el, attrs, ctrl){
					//delete array notation
					attrs.detailButton = attrs.detailButton.slice(0, -1);
					attrs.detailButton = attrs.detailButton.slice(1);

					//append html
					var el_new = '<a ng-click="show_details('+attrs.detailButton+')"><i class="fa fa-info"></i></a>';

					//remove directive to prevent infinite loop
					el.removeAttr("detail-button");
					//delete current data
					el.empty();
					//append new element
					el.append(el_new);

					$compile(el)(scope);
				}
			};
		}
	};
}]);

//create radio button for tables
angular.module('fidis-framework-directives').directive('radioButton', ['$compile', function($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				pre: function preLink(scope, el, attrs, ctrl){
					//remove radioButton directive to prevent infinite loop
					el.removeAttr("radio-button");
					//clear inner html
					el.html('');
					//append radio button
					el.append("<input permission type='radio' name='radio_button' ng-model='radio_checked' value='"+attrs.radioButton+"' ng-click='selected("+attrs.radioButton+")' />");
					$compile(el)(scope);
				}
			};
		}

	};
}]);

//create select button for tables
angular.module('fidis-framework-directives').directive('selectButton', ['$compile', function($compile){
	return {
		restrict: 'A',
		compile: function compile(tElement, tAttrs){
			return {
				pre: function preLink(scope, el, attrs, ctrl){
					//remove selectButton directive to prevent infinite loop
					el.removeAttr("select-button");
					//clear inner html
					el.html('');

					//append select button industry_sector specific
					el.append("<input permission type='checkbox' ng-true-value='1' ng-false-value='0' name='checkbox' ng-model='"+attrs.selectButton+"' ng-click='select("+attrs.selectButton+", $index)' />");
					$compile(el)(scope);
				}
			};
		}

	};
}]);