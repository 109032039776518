angular.module('fidis-framework-factories', ['ngRoute', 'fidis-framework-config', 'fidis-framework-timeout', 'ui.bootstrap.modal']);

angular.module('fidis-framework-factories').factory('httpInterceptor', ['$q', '$injector', '$rootScope', 'fidisFrameworkConfig', function($q, $injector, $rootScope, fidisFrameworkConfig) {
	var open_error_modal = function(data){
		var uibModal = $injector.get("$uibModal");
		var modalInstance = uibModal.open({
											  animation: true,
											  keyboard : false,
											  backdrop : 'static',
											  templateUrl: 'serverErrorModal/tpl',
											  controller: 'serverErrorModalCtrl',
											  resolve: {
												  status: function() {
													  return data;
												  }
											  }
										  }).result.then(function() {
		}, function(){
		});
	};
	return {
		request: function(config) {
			if (config.data && typeof config.data === 'object') {
				config.data = $.param(config.data);
			}
			return config || $q.when(config);
		},
		responseError: function (rejection){
			//console.log(rejection);
			//console.log('rejected with: ' + rejection.status);

			if(rejection.status === 403){
				var locaction = $injector.get("$location");
				locaction.path(fidisFrameworkConfig.path_login);
				return $q.resolve(rejection);
			}
			//close all open modal windows
			var uibModalStack = $injector.get("$uibModalStack");
			uibModalStack.dismissAll();
			if(rejection.status === 500 || (fidisFrameworkConfig.http_autorization === undefined && rejection.status === -1)){
				open_error_modal(rejection.status);
			}
			if(fidisFrameworkConfig.http_autorization !== undefined){
				if(fidisFrameworkConfig.http_redirect != undefined && fidisFrameworkConfig.http_redirect != ''){
					window.location = fidisFrameworkConfig.http_redirect;
				}else{
					open_error_modal(401);
				}
			}
			return $q.reject(rejection);


			/*
			 if(rejection.status == 401)
			 $location.path('/'+$rootScope.globals.relative_path+'login');

			 /*
			 if (rejection.status !== 401) {
			 return rejection;
			 }
			 var deferred = $q.defer();


			 return deferred.promise;
			 */
		},
		response: function(response) {
			//reset timeout
			if(response.config.data !== "type=logout"){
				if(response.config.data !== undefined){
					var TimeoutService = $injector.get("TimeoutService");
					TimeoutService.timeout_reset();
				}
			}

			// do something on success
			//console.log(response);
			//console.log(response.status);
			return response;
		}
	};
}]);