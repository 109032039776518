angular.module('fidis-framework-checkbox', []);

//create the checkbox
angular.module('fidis-framework-checkbox').directive('fidisCheckbox', [function(){
	return {
		restrict: 'A',
		scope: {
			fidisCheckbox: '=',
			fidisCheckboxDisabled: '=',
			fidisCheckboxLabel: '@'
		},
		templateUrl: 'fidisCheckbox/tpl',
		replace: true
	};
}]);