angular.module('fidis-framework-smart-table', ['smart-table', 'fidis-framework-modal-info', 'fidis-framework-config', 'fidis-framework-login']);

angular.module('fidis-framework-smart-table').config(['$provide', '$injector', 'stConfig', function fidisSmartTableConfig($provide, $injector, stConfig) {
	//set smart table pagination template
	stConfig.pagination.template = 'pagination_pipe/tpl';
	//increase smart Table pipe delay to prevent double loading for default stSort
	stConfig.sort.delay = 100;
	stConfig.pipe.delay = 200;
	stConfig.endpoint = '';
	stConfig.tableCache = 5;
	stConfig.translate = false;
	stConfig.translation = {};
	stConfig.translation.default = {
		tableinfo: 'Showing {{table_info.start| integer}} to {{table_info.to| integer}} of {{table_info.total| integer}} entries',
		tableinfo_filtered: 'Showing {{table_info.start | integer}} to {{table_info.to | integer}} of {{table_info.filtered | integer}} entries (filtered from {{table_info.total | integer}} total entries)',
		first: 'First',
		previous: 'Previous',
		next: 'Next',
		last: 'Last'
	};
	stConfig.translation.marker = {
		tableinfo: 'TABLEINFO',
		tableinfo_filtered: 'TABLEINFO_FILTERED',
		first: 'TABLEINFO_FIRST',
		previous: 'TABLEINFO_PREVIOUS',
		next: 'TABLEINFO_NEXT',
		last: 'TABLEINFO_LAST'
	};

	// extending stPagination to scroll the table body to top on page change
	if ($injector.has('stPaginationDirective')){
		$provide.decorator('stPaginationDirective', ['$delegate', function ($delegate) {
			var directive = $delegate[0];
			var link = directive.link;

			directive.compile = function() {
				return function(scope, element, attrs, ctrl) {
					link.apply(this, arguments);
					//view -> table state
					scope.selectPage = function (page) {
						if (page > 0 && page <= scope.numPages) {
							ctrl.slice((page - 1) * scope.stItemsByPage, scope.stItemsByPage);
							//get table body's
							var table_bodys = element.parent().parent().find('tbody');
							//loop throw body's and set scrollTop to 0
							for(var i = 0; i < table_bodys.length; i++){
								table_bodys[i].scrollTop = 0;
							}
						}
					};
				};
			};
			return $delegate;
		}]);
	}
}]);

angular.module('fidis-framework-smart-table').run(['$log', '$templateCache', 'stConfig', function fidisSmartTableRun($log, $templateCache, stConfig) {
	//check for ngMessages
	try{
		angular.module('smart-table');
	}catch(error){
		$log.error('SmartTable is missing');
	}

	var info = {};
	if(!stConfig.translate){
		info = stConfig.translation.default;
	}else{
		info = stConfig.translation.marker;
		angular.forEach(info, function(value, key) {
			switch(key){
				case('tableinfo'):
					info[key] = '{{"'+value+'"|translate:table_info}}';
					break;
				case('tableinfo_filtered'):
					info[key] = '{{"'+value+'"|translate:table_info}}';
					break;
				default:
					info[key] = '{{"'+value+'"|translate}}';
					break;
			}
		});
	}


	//set pagination template
	var pagination = '<div st-summary class="col-md-4">'+
						 '<div ng-if="table_info.filtered == table_info.total" class="col-md-12 pagination_show">'+
							info.tableinfo+
						 '</div>'+
						 '<div ng-if="table_info.filtered != table_info.total" class="col-md-12 pagination_show">'+
							info.tableinfo_filtered+
						 '</div>'+
					 '</div>'+
					 '<div class="col-md-8">'+
						 '<nav ng-if="numPages >= 2" class="table_pagination">'+
							 '<ul class="pagination">'+
								 '<li ><a ng-click="selectPage(1)" class="paginate_button first" ng-class="{disabled: currentPage==\'1\'}">'+info.first+'</a></li>'+
								 '<li><a ng-click="selectPage(currentPage - 1)" class="paginate_button previous" ng-class="{disabled: currentPage==\'1\'}">'+info.previous+'</a></li>'+
								 '<li ng-repeat="page in pages"><a ng-click="selectPage(page)" class="paginate_button"  ng-class="{current: page==currentPage}">{{page}}</a></li>'+
								 '<li ng-hide="numPages <= 5 || pages[pages.length-1] == numPages"><span class="ellipsis">...</span></li>'+
								 '<li ng-hide="numPages <= 5 || pages[pages.length-1] == numPages"><a class="paginate_button" ng-click="selectPage(numPages)">{{numPages}}</a></li>'+
								 '<li><a ng-click="selectPage(currentPage + 1)" class="paginate_button next" ng-class="{disabled: currentPage==numPages}">'+info.next+'</a></li>'+
								 '<li><a ng-click="selectPage(numPages)" class="paginate_button last" ng-class="{disabled: currentPage==numPages}">'+info.last+'</a></li>'+
							 '</ul>'+
						 '</nav>'+
						 '<nav ng-if="numPages < 2" class="table_pagination">'+
							'<ul class="pagination"></ul>'+
						 '</nav>'+
					 '</div>';
	$templateCache.put('pagination/tpl', pagination);

	//set pagination pipe template
	var pagination_pipe = '<nav ng-if="numPages >= 2" class="table_pagination">'+
							  '<ul class="pagination">'+
								  '<li ><a ng-click="selectPage(1)" class="paginate_button first" ng-class="{disabled: currentPage==\'1\'}">'+info.first+'</a></li>'+
								  '<li><a ng-click="selectPage(currentPage - 1)" class="paginate_button previous" ng-class="{disabled: currentPage==\'1\'}">'+info.previous+'</a></li>'+
								  '<li ng-repeat="page in pages"><a ng-click="selectPage(page)" class="paginate_button"  ng-class="{current: page==currentPage}">{{page}}</a></li>'+
								  '<li ng-hide="numPages <= 5 || pages[pages.length-1] == numPages"><span class="ellipsis">...</span></li>'+
								  '<li ng-hide="numPages <= 5 || pages[pages.length-1] == numPages"><a class="paginate_button" ng-click="selectPage(numPages)">{{numPages}}</a></li>'+
								  '<li><a ng-click="selectPage(currentPage + 1)" class="paginate_button next" ng-class="{disabled: currentPage==numPages}">'+info.next+'</a></li>'+
								  '<li><a ng-click="selectPage(numPages)" class="paginate_button last" ng-class="{disabled: currentPage==numPages}">'+info.last+'</a></li>'+
							  '</ul>'+
						  '</nav>'+
						  '<nav ng-if="numPages < 2" class="table_pagination">'+
							  '<ul class="pagination"></ul>'+
						  '</nav>';
	$templateCache.put('pagination_pipe/tpl', pagination_pipe);
}]);

angular.module('fidis-framework-smart-table').factory('SmartTable_pipeline', ['$q', '$filter', '$http', '$rootScope', '$location', 'stConfig', 'SessionService', function SmartTable_pipeline($q, $filter, $http, $rootScope, $location, stConfig, SessionService) {
	var current_site = 0;
	var site_counter = 1;
	var cache_block = 0;
	var sort,sort_order;
	var number_of_items_filterd = 0;
	var number_of_items = 0;
	var globale_search_value	= '';
	var where_clause = '';
	var where_clause_check = false;
	var company_changed = false;
	var path_check = '';
	var predicateObject_backup = {};

	function reset(){
		current_site = 0;
		site_counter = 1;
		cache_block = 0;
		sort = '';
		sort_order = '';
		number_of_items_filterd = 0;
		number_of_items = 0;
		globale_search_value	= '';
		where_clause = '';
		where_clause_check = false;
		company_changed = false;
		path_check = '';
		predicateObject_backup = {};
	}

	function getPage(params ,options, data_endpoint) {
		if($rootScope.globals.table_item_number != options.number)
			$rootScope.globals.table_item_number = options.number;

		if (data_endpoint === undefined) { data_endpoint = 'smart_table'; }

		//set where clause to false if undefined
		if(options.where_clause === undefined){
			options.where_clause = false;
			where_clause = '';
		}else{
			if(where_clause !== ''){
				where_clause_check = false;
				angular.forEach(options.where_clause, function(value, key) {
					if(value != where_clause[key]){
						where_clause_check = true;
					}
				});
			}
		}
		//set default datachanged parameter if undefined
		if(options.datachanged === undefined)
			options.datachanged = false;
		//define deferred
		var deferred = $q.defer();
		var new_site = Math.floor(options.start/options.number)+1;
		//define limit
		var limit = options.number*options.page_cache;
		//define a sort checker
		var sort_order_check;
		if(params.sort.reverse === undefined){
			sort_order_check = undefined;
		}else{
			sort_order_check = params.sort.reverse?'desc':'asc';
		}
		var path = $location.path().slice(1);

		// console.log('_________________');
		// console.log((number_of_items == number_of_items_filterd && (Math.ceil(number_of_items/options.number) != options.numberOfPages) || number_of_items != number_of_items_filterd && (Math.ceil(number_of_items_filterd/options.number) != options.numberOfPages)));
		// console.log((options.cache.length == 0 || cache_block != Math.ceil(new_site/options.page_cache)) && current_site != new_site);
		// console.log(params.sort.predicate != undefined && params.sort.predicate != sort);
		// console.log(sort_order_check != sort_order);
		// console.log((params.search.predicateObject != undefined && JSON.stringify(params.search.predicateObject) != JSON.stringify(predicateObject_backup)) && globale_search_value != params.search.predicateObject['$']);
		// console.log(where_clause != false && where_clause_check == true);
		// console.log(options.datachanged == true);
		// console.log(company_changed != SessionService.session.settings.companycode);
		// console.log(path != path_check);
		// console.log('_________________');
		//get data from database if the cache is exhausted
		if((number_of_items == number_of_items_filterd && (Math.ceil(number_of_items/options.number) != options.numberOfPages) || number_of_items != number_of_items_filterd && (Math.ceil(number_of_items_filterd/options.number) != options.numberOfPages))
			|| (options.cache.length == 0 || cache_block != Math.ceil(new_site/options.page_cache)) && current_site != new_site
			|| params.sort.predicate != undefined && params.sort.predicate != sort
			|| sort_order_check != sort_order
			|| (params.search.predicateObject != undefined && JSON.stringify(params.search.predicateObject) != JSON.stringify(predicateObject_backup)) && globale_search_value != params.search.predicateObject['$']
			|| where_clause != false && where_clause_check == true
			|| options.datachanged == true
			|| company_changed != SessionService.session.settings.companycode
			|| path != path_check){
			//portal company change check
			company_changed = angular.copy(SessionService.session.settings.companycode);

			//reset cache if the table is filtered
			if(current_site == new_site){
				new_site = 1;
				options.start = 0;
			}

			if(path != path_check && sort_order_check == undefined)
				sort_order = undefined;
			//set path check
			path_check = path;
			where_clause = options.where_clause;
			//get cache block
			//cache_block = (Math.ceil(new_site/options.page_cache)-1)*options.page_cache+1;
			cache_block = Math.ceil(new_site/options.page_cache);

			var order;
			if(params.sort.predicate !== undefined){
				sort = params.sort.predicate;
				sort_order = (params.sort.reverse)?'desc':'asc';
				order = [
					{
						column: sort,
						dir: sort_order
					}
				];
			}else{
				order = undefined;
			}
			var column = [];
			var predicateObject = params.search.predicateObject;
			if(predicateObject !== undefined){
				predicateObject_backup = angular.copy(predicateObject);
				for (var key in predicateObject) {
					if(key === '$'){
						globale_search_value = predicateObject['$'];
					}else{
						var temp = key.split('_');
						//set strict
						if(predicateObject[key]['strict'] === undefined){
							column.push({
								data: temp[1],
								search: {
									regex: false,
									value: predicateObject[key],
									strict: false
								}
							});
						}else{
							column.push({
								data: temp[1],
								search: {
									regex: false,
									value: predicateObject[key]['value'],
									strict: predicateObject[key]['strict']
								}
							});
						}
						//set daterange
						if(temp.length === 3 && temp[2] === 'selected'){
							column[column.length-1].is_daterange = true;
						}
					}
				}
			}
			var start = (cache_block-1)*limit;
			var search = {regex:false, value: globale_search_value};
			//get data
			$http.post(path+stConfig.endpoint ,{type: data_endpoint, order: order, column: column, length: limit, start: start, search: search, where: options.where_clause, get_filter: false})
				.then(function(response){
					var result = response.data.data;
					var items_to = options.start+options.number;
					var data = [];
					if(result !== undefined){
						site_counter = new_site%options.page_cache;
						if(site_counter === 0)
							site_counter = options.page_cache;
						number_of_items_filterd = response.data.recordsFiltered;
						number_of_items = response.data.recordsTotal;


						if(items_to > number_of_items)
							items_to = number_of_items;
						if(items_to > number_of_items_filterd)
							items_to = number_of_items_filterd;
						if(number_of_items !== number_of_items_filterd)
							options.numberOfPages = Math.ceil(number_of_items_filterd/ options.number);
						else
							options.numberOfPages = Math.ceil(response.data.recordsTotal / options.number);


						if(result !== undefined)
							data = result.slice((site_counter-1)*options.number, site_counter*options.number);
					}else{
						result = [];
						site_counter = 0;
						items_to = 0
					}

					deferred.resolve({
						cache: result,
						data: data,
						numberOfPages: options.numberOfPages,
						site_counter: site_counter,
						number_of_items_filterd: number_of_items_filterd,
						number_of_items: number_of_items,
						items_to:  items_to
					});
				});
		}else{
			site_counter = new_site%options.page_cache;
			if(site_counter === 0)
				site_counter = options.page_cache;

			var items_to = options.start+options.number;
			if(items_to > number_of_items)
				items_to = number_of_items;
			if(items_to > number_of_items_filterd)
				items_to = number_of_items_filterd;


			if(number_of_items !== number_of_items_filterd)
				options.numberOfPages = Math.ceil(number_of_items_filterd/ options.number);

			var data = {};
			if(site_counter === 1)
				data = options.cache.slice(0, options.number);
			else
				data = options.cache.slice((site_counter-1)*options.number, site_counter * options.number);

			//set cache
			deferred.resolve({
				cache: options.cache,
				data: data,
				numberOfPages:options.numberOfPages,
				site_counter: site_counter,
				number_of_items_filterd: number_of_items_filterd,
				number_of_items: number_of_items,
				items_to:  items_to
			});

			//get cache block
			cache_block = Math.ceil(new_site/options.page_cache);
		}
		current_site = new_site;
		return deferred.promise;
	}

	return {
		getPage: getPage,
		reset: reset
	};
}]);

angular.module('fidis-framework-smart-table').factory('SmartTable_export', ['$q', '$filter', '$http', '$rootScope', '$location', 'stConfig', 'SessionService', function SmartTable_export($q, $filter, $http, $rootScope, $location, stConfig, SessionService) {

	var sort,sort_order;
	var globale_search_value	= '';
	var where_clause;
	function exportCSV(params, options, arrHeader, exportHeader, filename, export_endpoint, delete_header) {

		//set where clause to false if undefined
		if(options.where_clause === undefined){
			where_clause = '';
		}else{
			where_clause = options.where_clause;
		}
		//set delete_header if its not set
		if(delete_header === undefined){
			delete_header = true;
		}

		if (export_endpoint === undefined){
			export_endpoint = 'smart_table';
		}
		var order;
		var column = [];
		if(params !== undefined){
			//set sorting
			if(params.sort.predicate !== undefined){
				sort = params.sort.predicate;
				sort_order = (params.sort.reverse)?'desc':'asc';
				order = [
					{
						column: sort,
						dir: sort_order
					}
				];
			}else{
				order = undefined;
			}
			//set search object
			var predicateObject = params.search.predicateObject;
			if(predicateObject !== undefined){
				for(var key in predicateObject){
					//set global search
					if(key === '$'){
						globale_search_value = predicateObject['$'];
					}else{
						//set column search
						if(params.search.predicateObject.hasOwnProperty(key)){
							var temp = key.split('_');
							if(predicateObject[key]['strict'] === undefined){
								column.push({
									data: temp[1],
									search: {
										regex: false,
										value: predicateObject[key],
										strict: false
									}
								});
							}else{
								column.push({
									data: temp[1],
									search: {
										regex: false,
										value: predicateObject[key]['value'],
										strict: predicateObject[key]['strict']
									}
								});
							}
							//set daterange picker search
							if(temp.length === 3 && temp[2] === 'selected'){
								column[column.length-1].is_daterange = true;
							}

						}
					}
				}
			}
		}
		var search = {regex:false, value: globale_search_value, strict: false};
		//get path
		var path = $location.path().slice(1);

		//create filter name
		var temp_array = path.split('/');
		var filter_name = temp_array[temp_array.length-1];

		//get data
		$http.post(path+stConfig.endpoint ,{type: export_endpoint, order: order, column: column, start: 0, search: search, limit: false, where: options.where_clause, get_filter: filter_name})
			.then(function(response){
				if(response.data.data !== undefined){
					//define line break
					var newLine = navigator.userAgent.match(/Windows/) ? "\r\n" : "\n";
					//array for output
					var output = [];
					//add header to output at first index
					//prepare for utf-8 with BOM ('\ufeff') for Umlaute
					var new_arrHeader = [];
					for(var k = 0; k<arrHeader.length; k++){
						if(exportHeader[k] == 1)
							new_arrHeader.push($filter('translate')(arrHeader[k]));
					}
					output[0] = '\ufeff' + new_arrHeader.join(';') + newLine;

					var header_check = [];
					//translate table config column header and check if the given headers from the template and the column names from the filter are identical
					for(var i = 0 ; i < response.data.filter.length; i++){
						if(arrHeader.indexOf(response.data.filter[i]['name_column']) === -1){
							header_check.push(i);
						}
					}
					//initialize variable to improve performance
					var temp_column_data = '';
					var temp_column_filter = '';
					var temp_column_filter_name = '';
					var temp_column_filter_data = '';
					var filter = response.data.filter;
					var filter_check = false;
					//prepare content for output
					for (i = 0 ; i < response.data.recordsFiltered ; i++) {
						if(delete_header === true){
							//delete entry that don't exist
							for(j = header_check.length-1 ; j >= 0 ; j--){
								if(export_endpoint === 'smart_table')
									response.data.data[i].splice(header_check[j], 1);
								//delete filter only once
								if(i === 0){
									response.data.filter.splice(header_check[j], 1);
								}
							}
						}
						if(delete_header === true && i === 0){
							filter = [];
							for(k = 0; k < arrHeader.length; k++){
								for(var l = 0; l < response.data.filter.length; l++){
									if(arrHeader[k] === response.data.filter[l]['name_column']){
										filter_check = false;
										for(var f = 0; f < filter.length; f++){
											if(filter[f]['name_column'] === response.data.filter[l]['name_column']){
												filter_check = true;
												break;
											}
										}
										if(!filter_check)
											filter.push(response.data.filter[l]);
									}
								}
							}
						}
						var val = '';
						var temp_column_data_backup = '';
						for (var j = 0 ; j < arrHeader.length ; j++){
							if(exportHeader[j] == 1){
								//set data variable
								temp_column_data = temp_column_data_backup = response.data.data[i][j];

								if (temp_column_data !== null){
									if(typeof temp_column_data === 'string'){
										// important! replace all semicolons and line breaks for Export
										temp_column_data = temp_column_data.replace(/"|'|;|\n|\r/g, "");
									}
								}else{
									temp_column_data = '';
								}
								/*
								 *  set export filter
								 */
								//check if array index is empty or if name is empty
								if((filter[j] !== undefined && filter[j]['name'] !== '')){
									//set filter variable
									temp_column_filter = filter[j];
									temp_column_filter_name = temp_column_filter['name'];
									temp_column_filter_data = temp_column_filter['data'];

									if(temp_column_filter['json'] !== undefined && temp_column_filter['json'] === true){
										temp_column_data = temp_column_data_backup;
									}

									//check if optional data is empty
									if(temp_column_filter_data !== ''){
										//check if filter is date or expiry because you can't use a variable which reference to another variable
										if(temp_column_filter_name === 'date' || temp_column_filter_name === 'expiry' || temp_column_filter_name === 'datetime' || temp_column_filter_name === 'datetime_to_date' || temp_column_filter_name === 'date_expanded'){
											val += $filter(temp_column_filter_name)(temp_column_data, SessionService.session.settings.date_format.display)

										}else if(temp_column_filter_name === 'insuranceCheck'){
											val += $filter(temp_column_filter_name)(temp_column_data, response.data.data[i][8], response.data.data[i][9]);
										}else{
											val += $filter(temp_column_filter_name)(temp_column_data, temp_column_filter_data);
										}
									}else{
										val += $filter(temp_column_filter_name)(temp_column_data);
									}
								}else{
									val += temp_column_data;
								}
								val += ";";
							}
						}
						//trim last character
						output[i + 1] = val.substring(0, val.length-1) + newLine;
					}
					//create file
					var file_data = new Blob(output,
											 {type: "data:text/csv;charset=utf-8"});
					saveAs(file_data, filename);
				}
			});
	}
	return {
		exportCSV: exportCSV
	};

}]);


//service to handle the smart table functions
angular.module('fidis-framework-smart-table').service('SmartTableService', ['$rootScope', '$filter', '$http', '$location', '$timeout', '$uibModal', 'stConfig', 'SmartTable_export', 'SmartTable_pipeline', 'InfoModalFactory', 'fidisFrameworkConfig', 'SessionService',  function SmartTableService($rootScope, $filter, $http, $location, $timeout, $uibModal, stConfig, SmartTable_export, SmartTable_pipeline, InfoModalFactory, fidisFrameworkConfig, SessionService) {
	var SmartTableService = {};
	var datepicker_range_index;
	var scope;
	var cleared = {};


	SmartTableService.default_init = function(scope_parent) {
		//initialize SmartTable (set show entries dropdown, set dropdown data if required)
		scope_parent = SmartTableService.initialize(scope_parent, true);
		//set date format
		scope_parent.date_format    = SessionService.session.settings.date_format.display;

		//call pipeline
		scope_parent.callServer = function(tableState){
			if(scope_parent.tableState_lower_ctrl === undefined){
				scope_parent.tableState_lower_ctrl = angular.copy(tableState);
			}
			SmartTableService.pipeline(scope_parent, tableState);
		};

		//export data function
		scope_parent.export_data = function(){
			SmartTableService.export(
				scope_parent.tableState,
				scope_parent.options,
				angular.element('#smart_table_header').find('th'),
				$('#smart_table_name').text(),
				[],
				[],
				'smart_table',
				true
			);
		};

		//download
		scope_parent.download = function(document_id, data){
			var filereq = '';
			filereq += '<input type="text" name="type" value="'+data+'"/>';
			filereq += '<input type="text" name="document_id" value="'+document_id+'"/>';
			$("<form action='"+fidisFrameworkConfig.endpoints.download+"' method='GET'>"+filereq+"</form>").appendTo('body').submit().remove();
		};

		scope_parent.clear_range = function(index){
			scope_parent = SmartTableService.datepicker_clear(index, scope_parent);
		};

		//select button
		scope_parent.select = function(input, row){
			var msg = {
				input: input,
				row: scope_parent.displayed[row]
			};
			//fill dropdown
			$http.post(path ,{type: 'select', msg: msg})
				.then(function(response){

				});
		};
		//delete function
		scope_parent.delete = function(type){
			if(type === undefined){
				type = 'item';
			}
			InfoModalFactory.info('delete', type, scope_parent.selected_delete, function(type, result){
				if(result != undefined && result.check == true){
					scope_parent.data_changed = true;
					scope_parent.callServer(scope_parent.tableState);
					scope_parent.data_changed = false;
				}
			});
		};
	};

	SmartTableService.default_init_details = function(scope_parent) {
		scope_parent.table_info = {};
		//set date format
		scope_parent.date_format    = SessionService.session.settings.date_format.display;
		/*
		 *
		 * Customized SmartTable stPagination Directive to ensure the functionality of the inner tables pagination
		 *
		 */
		scope_parent.set_pagination = function(tableState){
			//pagination copy from smart-table.js for pagination in a smart-table in a smart-table
			scope_parent.stItemsByPage    = 20;
			scope_parent.stDisplayedPages = 5;
			scope_parent.currentPage      = 1;
			scope_parent.pages            = [];

			function redraw(){
				var paginationState   = tableState.pagination;
				var start             = 1;
				var end;
				var i;
				var prevPage          = scope_parent.currentPage;
				scope_parent.totalItemCount = paginationState.totalItemCount;
				scope_parent.currentPage    = Math.floor(paginationState.start/paginationState.number)+1;

				start = Math.max(start, scope_parent.currentPage-Math.abs(Math.floor(scope_parent.stDisplayedPages/2)));

				end = start+scope_parent.stDisplayedPages;

				if(end > paginationState.numberOfPages){
					end   = paginationState.numberOfPages+1;
					start = Math.max(1, end-scope_parent.stDisplayedPages);
				}

				scope_parent.pages    = [];
				scope_parent.numPages = paginationState.numberOfPages;
				for(i = start; i < end; i++){
					scope_parent.pages.push(i);
				}

				// if (prevPage !== scope_parent.currentPage) {
				//     scope_parent.stPageChange({newPage: scope_parent.currentPage});
				// }
			}

			//table state --> view
			scope_parent.$watch(function(){
				return scope_parent.tableState.pagination;
			}, redraw, true);

			//scope --> table state  (--> view)
			scope_parent.$watch('stItemsByPage', function(newValue, oldValue){
				if(newValue !== oldValue){
					scope_parent.selectPage(1);
				}
			});

			scope_parent.$watch('stDisplayedPages', redraw);

			//view -> table state
			scope_parent.selectPage = function(page){
				if(page > 0 && page <= scope_parent.numPages){
					tableState.pagination.start  = (page-1)*scope_parent.stItemsByPage;
					tableState.pagination.number = scope_parent.stItemsByPage;
					scope_parent.callServer_details(tableState);
				}
			};

			if(!scope_parent.tableState.pagination.number){
				tableState.pagination.start  = 0;
				tableState.pagination.number = scope_parent.stItemsByPage;
				scope_parent.callServer_details(tableState);
			}
		};


		//datepicker status
		scope_parent.status_array = {};
		scope_parent.$watch('status_array', function(new_value, old_value){
			//call the pipeline
			if(scope_parent.firstLoadIsDone !== undefined){
				var temp_scope = SmartTableService.set_tableState(new_value, old_value, scope_parent);
				if(temp_scope !== ''){
					scope_parent = temp_scope;
					scope_parent.callServer_details(scope_parent.tableState)
				}
			}
		},true);

		scope_parent.clear_range = function(index){
			scope_parent = SmartTableService.datepicker_clear(index, scope_parent);
		};

		//call pipeline
		scope_parent.callServer_details = function(tableState){
			SmartTableService.pipeline(scope_parent, tableState, undefined, true);
		};
	};

	/**
	 * @fileOverview function to initialize the SmartTable (set show entries dropdown, set dropdown data if required)
	 *
	 * @param {object} scope_parent
	 * scope of the current controller
	 *
	 * @param {boolean} dropdown
	 * set to true if dropdown's are used
	 *
	 * @example
	 * SmartTableService.initialize($scope, true);
	 *
	 * @return {object} scope_parent
	 */
	SmartTableService.initialize = function(scope_parent, dropdown, endpoint) {
		if(dropdown === true){
			var path = $location.path().slice(1);
			scope_parent.loading_dropdown_data = true;
			//fill dropdown
			$http.post(path+'/req' , {type: 'dropdown'})
				.then(function(response){
					if(response.data.type != 'warning'){
						angular.forEach(response.data, function(object, key){
							scope_parent[key] = object;
							scope_parent[key].forEach(function(value){
								if(typeof value == 'object'){
									value.translation = $filter('translate')(value.name);
								}
							});
						});
						scope_parent.translate_dropdown();
						scope_parent.loading_dropdown_data = false;
					}
				});

			//translate the dropdown's
			scope_parent.translate_dropdown = function(){
				if(scope_parent.password_change !== undefined){
					scope_parent.password_change.forEach(function(value){
						value.translation = $filter('translate')(value.name);
					});
				}
				if(scope_parent.locked !== undefined){
					scope_parent.locked.forEach(function(value){
						value.translation = $filter('translate')(value.name);
					});
				}
				if(scope_parent.status !== undefined){
					scope_parent.status.forEach(function(value){
						value.translation = $filter('translate')(value.name);
					});
				}
				if(scope_parent.permission !== undefined){
					scope_parent.permission.forEach(function(value){
						value.translation = $filter('translate')(value.name);
					});
				}
			};
		}

		if(endpoint !== undefined){
			stConfig.endpoint = endpoint;
		}

		//datepicker status
		scope_parent.status_array = {};
		scope_parent.$watch('status_array', function(new_value, old_value){
			//call the pipeline
			if(scope_parent.firstLoadIsDone !== undefined){
				var temp_scope = SmartTableService.set_tableState(new_value, old_value, scope_parent);
				if(temp_scope !== ''){
					scope_parent = temp_scope;
					scope_parent.callServer(scope_parent.tableState)
				}
			}
		},true);

		scope_parent.show_entries = [20, 50, 100];
		if($rootScope.globals.table_item_number !== undefined){
			for(var i = 0; i < scope_parent.show_entries.length; i++){
				if(scope_parent.show_entries[i] === $rootScope.globals.table_item_number)
					scope_parent.selected_show_entries = scope_parent.show_entries[i];
			}
		}else{
			scope_parent.selected_show_entries = scope_parent.show_entries[0];
		}

		scope_parent.select_deletions = function(id){
			if(scope_parent.selected_delete == undefined){
				scope_parent.selected_delete = [];
			}
			var index = scope_parent.selected_delete.indexOf(id);
			if(index != -1){
				scope_parent.selected_delete.splice(index, 1);
			}else{
				scope_parent.selected_delete.push(id);
			}
		};

		return scope_parent;
	};


	SmartTableService.pipeline = function(scope_parent, tableState, where_clause, details) {
		if(scope_parent.cache === undefined){
			scope_parent.cache = [];
		}
		if(scope_parent.numberOfPages === undefined){
			scope_parent.numberOfPages = 0;
		}
		if(tableState !== undefined){
			if(!scope_parent.firstLoadIsDone) {
				scope_parent.firstLoadIsDone = true;
				scope_parent.isLoading = true;
				scope_parent.data_changed = true
			}
			scope_parent.tableState = tableState;
			if(scope_parent.tableState.pagination.number === undefined)
				scope_parent.tableState.pagination.number = scope_parent.selected_show_entries;

			if(typeof pagination === 'undefined')
				var pagination = tableState.pagination;

			var data_changed = scope_parent.data_changed;
			//SmartTable options
			scope_parent.options = {
				start: pagination.start,
				number: pagination.number,
				order:[{column: 0, dir: true}],
				page_cache: stConfig.tableCache,
				cache: scope_parent.cache,
				numberOfPages: scope_parent.numberOfPages,
				datachanged: data_changed
			};
			scope_parent.data_changed = false;
			if(where_clause !== undefined)
				scope_parent.options['where_clause'] = where_clause;

			var endpoint;

			if(details === true){
				endpoint = 'smart_table_details';
			}
			//request to get the data from database DON'T TOUCH IT
			SmartTable_pipeline.getPage(tableState, scope_parent.options, endpoint).then(function (result) {
				if(!scope_parent.firstLoadIsDone){
					$timeout(function(){
						set_data();
					},600);
				}else{
					set_data();
				}
				function set_data(){
					if(result !== undefined){
						scope_parent.cache = result.cache;
						if(details !== true){
							scope_parent.displayed = result.data;
						}
						scope_parent.numberOfPages = result.numberOfPages;
						scope_parent.options.cache = scope_parent.cache;
						//set table info parameters
						scope_parent.table_info = {};
						scope_parent.table_info.start = scope_parent.options.start;
						if(result.number_of_items !== 0 && result.number_of_items_filterd !== 0)
							scope_parent.table_info.start++;
						scope_parent.table_info.to = result.items_to;
						scope_parent.table_info.total = result.number_of_items;
						scope_parent.table_info.filtered = result.number_of_items_filterd;
						//set table pagination parameters
						tableState.pagination = {
							start: scope_parent.options.start, // start index
							number: scope_parent.options.number, //number of item on a page
							numberOfPages: result.numberOfPages //total number of pages
						};
						if((scope_parent.table_info.total == scope_parent.table_info.filtered && fidisFrameworkConfig.items_max < scope_parent.table_info.total) ||
							(scope_parent.table_info.total >  scope_parent.table_info.filtered && fidisFrameworkConfig.items_max < scope_parent.table_info.filtered)){
							scope_parent.export_disable = true;
						}else{
							scope_parent.export_disable = false;
						}
						scope_parent.isLoading = false;
						if(details === true){
							scope_parent.display_details = result.data;
							if(scope_parent.stItemsByPage == undefined){
								scope_parent.set_pagination(tableState);
							}

							if(tableState.search.predicateObject !== undefined){
								scope_parent.tableState_lower_ctrl.search.predicateObject = {};
								for (var key in tableState.search.predicateObject) {
									scope_parent.tableState_lower_ctrl.search.predicateObject[key] = tableState.search.predicateObject[key];
								}
							}
							for (var key in tableState.sort) {
								scope_parent.tableState_lower_ctrl.sort[key] = tableState.sort[key];
							}
						}
						//reset delete array
						if(scope_parent.selected_delete !== undefined){
							scope_parent.selected_delete = [];
						}
					}
				}
				scope_parent.firstLoadIsDone = true;
				return scope_parent;
			});
		}
	};


	/**
	 * @fileOverview function for the export of the SmartTable data
	 *
	 * @param {object} tableState
	 * tableState of the SmartTable
	 *
	 * @param {object} options
	 * options of the SmartTable
	 *
	 * @param {object} smart_table_header
	 * all SmartTable DOM header
	 *
	 * @param {string} smart_table_name
	 * SmartTable name
	 *
	 * @param {object} array_header
	 * names of the exported column headers, only for special cases
	 *
	 * @param {object} array_export
	 * should it be exported or not only for special cases
	 *
	 * @param {string} export_endpoint
	 * set the export endpoint if it isn't set it used default smart_table endpoint
	 *
	 * @example
	 * //non special
	 * SmartTableService.export(
	 *	    $scope.tableState,
	 *       angular.element('#smart_table_header').find('th'),
	 *       angular.element('#smart_table_name').text(),
	 *		[],
	 *		[]
	 *   );
	 *
	 *  //special
	 * SmartTableService.export(
	 *	    $scope.tableState,
	 *       angular.element('#smart_table_header').find('th'),
	 *       $filter('translate')('TBLAV_TITLE'),
	 *		[
	 *		$filter('translate')('TBLAV_CUST_ID'),
	 *		$filter('translate')('TBLAV_DOCUMENT_NUMBER')
	 *		],
	 *		[
	 *		true,
	 *		true
	 *		],
	 *		'export'
	 *   );
	 * @return void
	 */
	SmartTableService.export = function(tableState, options, smart_table_header, smart_table_name, array_header, array_export, export_endpoint, delete_header) {
		var datum = new Date();
		var predicate_object_backup = angular.copy(tableState);
		var table_header = [];
		var table_search = [];
		function get_element(selector){
			return [].slice.call(document.querySelectorAll(selector));
		}

		if(array_header.length !== 0){
			table_header = get_element("#smart_table_header th");
			table_search = get_element("#smart_table_header_search th");

			table_header = table_header.concat(get_element("#smart_table_header_details th"));
			table_search = table_search.concat(get_element("#smart_table_header_details_search th"));

			var table_header_length_2 = table_header.length;
			var element, element_search, name, value, value_search;
			var sort_predicate,
				search_predicate,
				temp_arrHeader_name,
				i,
				current_table_header = [],
				predicated_object = {},
				temp_search_header = '';

			if(predicate_object_backup === undefined)
				predicate_object_backup = {};
			//get current header marker and values
			for(i = 0; i < table_header_length_2; i++){
				element = angular.element(table_header[i]);
				element_search = angular.element(table_search[i]).children()[0];

				name = element.children().attr('translate');
				value = element.attr('st-sort');
				if(element_search !== undefined){
					element_search = angular.element(element_search);
					value_search = element_search.attr('st-search');
					if(value_search === undefined){
						value_search = element_search.attr('st-select-search');
					}
					if(value_search === undefined){
						value_search = angular.element(element_search.children()[0]).attr('date-range')+'_selected';
					}
				}

				if(name !== undefined){
					current_table_header.push({
												  "name": name,
												  "sort": value,
												  "search": value_search
											  });
					if(tableState.sort.predicate == value){
						temp_arrHeader_name = name;
					}
				}
			}
			//set the proper sort column
			if(tableState.sort.predicate !== undefined){
				for(i = 0; i < array_header.length;i++){
					if(array_header[i] === temp_arrHeader_name){
						sort_predicate = i;
						break;
					}
				}
				predicate_object_backup.sort.predicate = sort_predicate;
			}
			//set the proper search columns
			if(tableState.search.predicateObject !== undefined){
				angular.forEach(tableState.search.predicateObject, function(object, key){
					search_predicate = undefined;
					var temp_search_index_array = key.split('_');
					for(i = 0; i < current_table_header.length; i++){
						var temp = current_table_header[i].search.split('_');
						if(key == temp[0]+'_'+temp[1]){
							temp_search_header = current_table_header[i].name;
							break;
						}
					}
					//get the sort column for the csv file
					for(i = 0; i<array_header.length;i++){
						if(array_header[i].trim() === temp_search_header){
							search_predicate = i;
							break;
						}
					}

					var is_date = '';
					if(temp_search_index_array.length === 3 && temp_search_index_array[2] === 'selected')
						is_date = '_'+temp_search_index_array[2];

					if(search_predicate !== undefined)
						predicated_object['search_'+search_predicate+is_date] = object;
				});
			}
			//set the search data
			if(temp_search_header !== '')
				predicate_object_backup.search.predicateObject = predicated_object;
		}else{
			smart_table_header.each(function(){
				//get translate marker
				array_header.push(angular.element(angular.element(this).children()[0]).attr('translate'));
				// arrHeader.push(this.textContent.trim());
				array_export.push(this.getAttribute("export"));
			});
		}
		var filename = smart_table_name + '_' + datum.getFullYear() + '_' + (datum.getMonth() + 1) + '_' + datum.getDate() + '.csv';
		SmartTable_export.exportCSV(predicate_object_backup, options, array_header, array_export, filename, export_endpoint, delete_header);
	};

	/**
	 * @fileOverview function to set the tableState on special tables
	 * @param value_new
	 * @param value_old
	 * @param scope_parent
	 * @returns {*}
	 */
	SmartTableService.set_tableState = function(value_new, value_old, scope_parent) {
		scope = scope_parent;
		datepicker_range_index = '';
		angular.forEach(value_new, function(object, key) {
			if(value_old[key] !== undefined){
				if(object !== value_old[key])
					datepicker_range_index = key;
			}else{
				datepicker_range_index = key;
			}
		});
		//set custom class for every  specific datepicker
		if(scope.range_options === undefined)
			scope.range_options = {};
		if(scope.range_options[datepicker_range_index] === undefined){
			scope.range_options[datepicker_range_index] = {
				customClass: function(data) {
					if(scope[datepicker_range_index+'_selected'] === undefined)
						scope[datepicker_range_index+'_selected'] = [];
					var temp_unix =  Math.round(+data.date.setHours(0, 0, 0, 0));
					if(scope[datepicker_range_index+'_selected'].indexOf(temp_unix) > -1 || scope[datepicker_range_index+'_selected'].indexOf(temp_unix+3600000) > -1) {
						return 'selected';
					}
					return '';
				}
			};
		}

		//check if the datepicker is closed and if its the first time the site load and if the value is undefined or null
		if(value_new[datepicker_range_index] == false && scope.firstLoadIsDone !== undefined){

			var temp_index = '';
			if(scope[datepicker_range_index] !== undefined || scope[datepicker_range_index] === null)
				temp_index = datepicker_range_index;
			else if(scope[datepicker_range_index+'_selected'] !== undefined || scope[datepicker_range_index+'_selected'] === null)
				temp_index = datepicker_range_index+'_selected';

			var temp_index_replaced = temp_index.replace('_selected', '');
			if(temp_index !== ''){
				var check = false;
				//set predicate object if its not set
				if(scope.tableState.search.predicateObject === undefined)
					scope.tableState.search.predicateObject = {};

				//set smaller var to improve readability
				var predicateObject = scope.tableState.search.predicateObject;
				var temp_value = predicateObject[temp_index];
				if(temp_value === undefined)
					temp_value = predicateObject[temp_index_replaced];
				// if the value is null delete the date from the search object else add it in sql format
				if(scope[temp_index] === null){
					delete(predicateObject[temp_index]);
					check = true;
					scope[temp_index] = undefined;
				}else{
					if(scope[datepicker_range_index+'_selected'] !== undefined){
						var temp_data = angular.copy(scope[temp_index]);
						temp_data = $filter('orderBy')(temp_data);
						if(temp_data.length > 1){
							//set initDate for the datepicker view
							scope.range_options[datepicker_range_index].initDate = new Date(temp_data[temp_data.length-1]);
							if(predicateObject[temp_index_replaced] !== undefined)
								delete(predicateObject[temp_index_replaced]);
							predicateObject[temp_index] = moment(temp_data[0]).format('YYYY-MM-DD')+'|'+moment(temp_data[temp_data.length-1]).format('YYYY-MM-DD');
							if(temp_value !== predicateObject[temp_index])
								check = true;
						}else if(temp_data[0] !== undefined){
							//set initDate for the datepicker view
							scope.range_options[datepicker_range_index].initDate = new Date(temp_data[0]);
							if(predicateObject[temp_index] !== undefined)
								delete(predicateObject[temp_index]);
							predicateObject[temp_index_replaced] = moment(temp_data[0]).format('YYYY-MM-DD');
							if(temp_value !== predicateObject[temp_index_replaced])
								check = true;
						}else if(cleared[temp_index] == true){
							cleared[temp_index] = false;
							if(predicateObject[temp_index] !== undefined)
								delete(predicateObject[temp_index]);
							check = true;
						}

					}else{
						predicateObject[temp_index] = moment(scope[temp_index]).format('YYYY-MM-DD');
						if(temp_value != predicateObject[temp_index])
							check = true;
					}
				}
				scope.tableState.search.predicateObject = predicateObject;
				if(check === true){
					return scope;
				}
			}
		}
		return '';
	};


	/**
	 * @fileOverview function to clear the datepicker
	 *
	 * @param {object} index
	 * set the index of the datepicker
	 *
	 * @param {boolean} scope_parent
	 *  scope of the current controller
	 *
	 * @example
	 * SmartTableService.datepicker_clear(index, $scope);
	 *
	 * @return {object} scope_parent
	 */
	SmartTableService.datepicker_clear = function(index, scope_parent) {
		scope_parent[index] = [];
		if(index.indexOf('_selected') > -1 && scope_parent.tableState.search.predicateObject !== undefined){
			if(scope_parent.tableState.search.predicateObject[index] !== undefined)
				delete(scope_parent.tableState.search.predicateObject[index]);
			else if(delete(scope_parent.tableState.search.predicateObject[index.replace('_selected', '')]))
				delete(scope_parent.tableState.search.predicateObject[index.replace('_selected', '')]);
		}else{
			scope_parent[index] = [];
		}
		//set checker for data changed
		cleared[index] = true;

		//close datepicker
		scope_parent.status_array[index.replace('_selected', '')] = false;

		return scope_parent;
	};

	SmartTableService.combine_tablestates = function(tablestate_first, tablestate_second){
		var temp_tableState = angular.copy(tablestate_first);
		if(tablestate_second !== undefined){
			if(temp_tableState.search.predicateObject  === undefined){
				temp_tableState.search.predicateObject = {};
			}
			for (var key in tablestate_second.search.predicateObject) {
				temp_tableState.search.predicateObject[key] = tablestate_second.search.predicateObject[key];
			}
		}
		for (var key in tablestate_second.sort) {
			temp_tableState.sort[key] = tablestate_second.sort[key];
		}
		return temp_tableState;
	};


	return SmartTableService;
}]);

//directive to define pagination without pipeline
angular.module('fidis-framework-smart-table').directive('stSummary', ['$timeout', function stSummary($timeout) {
	return {
		require: '^stTable',
		link: function(scope, element, attrs, stTable) {
			scope.table_info = {};
			scope.table_info.total = 0;
			scope.table_info.filtered = 0;
			scope.$watch('currentPage', function() {
				$timeout(function() {
					if(scope.table_info === undefined)
						scope.table_info = {};
					var pagination = stTable.tableState().pagination;
					scope.table_info.start = pagination.start + 1;
					if(scope.currentPage === pagination.numberOfPages)
						scope.table_info.to = pagination.totalItemCount;
					else
						scope.table_info.to = scope.table_info.start + scope.stItemsByPage - 1;

					if(scope.table_info.total === undefined)
						scope.table_info.total = pagination.totalItemCount;

					if(scope.table_info.filtered === undefined)
						scope.table_info.filtered = scope.table_info.total;
				}, 150);
			});

			scope.$watch(stTable.getFilteredCollection, function(val) {

				var tableState = stTable.tableState();


				function isEmptyObject(obj) {
					var name;
					angular.forEach(obj, function(name) {
						return false;
					});
					return true;
				}
				if(scope.table_info !== undefined){
					scope.table_info.total = tableState.pagination.totalItemCount;
				}

				if(scope.table_info === undefined)
					scope.table_info = {};
				scope.table_info.filtered = (val || []).length;
				scope.table_info.to = tableState.pagination.number;
				if(scope.table_info.to > scope.table_info.filtered)
					scope.table_info.to = scope.table_info.filtered;
				else if(scope.table_info.to > scope.table_info.total)
					scope.table_info.to = scope.table_info.total;
			})
		}
	};
}]);
angular.module('fidis-framework-smart-table').directive('stRatio', [function stRatio(){
	return {
		link:function(scope, element, attr){
			var smart_table_header = element.parent();
			if(smart_table_header.attr('id') != undefined && (smart_table_header.attr('id') == 'smart_table_header' || smart_table_header.attr('id') == 'smart_table_header_details')){
				var element_ratio_count = smart_table_header.parent().attr('st-ratio-count');
				scope.ratio_count = parseFloat(element_ratio_count);
			}
			var ratio = attr.stRatio/scope.ratio_count*100;
			element.css('width',+ratio+'%');

		}
	};
}]);
angular.module('fidis-framework-smart-table').directive('stRatioCount', ['$rootScope', '$compile', '$parse', 'PermissionCheckService', function stRatioCount($rootScope, $compile, $parse, PermissionCheckService){
	return {
		restrict: 'A',
		priority:900,
		compile:function(tElement, tAttrs){
			//get header inside compile, so that the function have all header before ngif will be triggered
			var header = angular.element(tElement.children()[0]).children();
			return {
				//calculations inside the pre function to loop throw the header and get the right ratio count
				//this prevent that the compile function will only trigger once inside a ng-repeat
				//the pre will be triggered for each element inside the ng-repeat
				pre: function preLink(scope, el, attrs) {
					var count = 0;
					for(var i = 0; i < header.length; i++){
						var head = angular.element(header[i]);
						//check for ngif and for permission before add the ratio to the count
						if((head.attr('ng-if') === undefined || $parse(head.attr('ng-if'))($rootScope)) &&
							(head.attr('permission') === undefined || !PermissionCheckService.check(head.attr('permission')))){
							count += parseFloat(head.attr('st-ratio'));
						}
					}
					count = count.toFixed(2);
					el.attr('st-ratio-count', count);
				}
			}
		}
	};
}]);

angular.module('fidis-framework-smart-table').directive('textTooltip',['$compile', '$timeout', function textTooltip($compile){
	return {
		restrict: 'A',
		scope: true,
		link: function(scope, el, attr, ctrl) {
			//set the tooltip state
			function set_tooltip_state(){
				//TODO REMOVE WATCHER FROM HTML AND ADD TOOLTIP DYNAMIC INSIDE THE SET_TOOLTIP_STATE TO IMPROVE PERFORMANCE
				var el_zero = el[0];
				//check for ngif
				if(el_zero['nodeName'] == '#comment'){
					el = angular.element(el_zero['nextSibling']);
				}

				var padding_left = el.css('padding-left').replace('px', '');
				var padding_right = el.css('padding-right').replace('px', '');
				var inner_width = 0;
				if(el.prop('tagName') != 'TD'){
					var parent = el.parent();
					inner_width = parent.innerWidth()-padding_left-padding_right;
				}else{
					inner_width = el.innerWidth()-padding_left-padding_right;
				}
				var dom_width = getTextWidthDOM(el.html(), el.css('font-size')+' '+el.css('font-family'));
				scope.tooltip_state = dom_width >= inner_width;
			}
			//create a temp span to get the correct width of the string
			function getTextWidthDOM(text, font){
				var f = font || '12px arial',
					o = angular.element('<span>' + text + '</span>')
						.css({'font':f, 'float':'left', 'white-space':'nowrap'})
						//.css({'visibility': 'hidden'})
						.appendTo(angular.element('body')),
					w = o.width();
				o.remove();
				return w;
			}
			el.on("mouseenter", function() {
				set_tooltip_state();
			});
			//set resize event to check if the text is larger than the div
			var old_data = el.html();
			// $timeout(set_tooltip_state, 0);
			//set tooltip
			el.attr('uib-tooltip', old_data);
			el.attr('tooltip-enable', 'tooltip_state');
			el.attr('tooltip-placement', 'top-left');
			//remove directive to prevent infinite loop
			el.removeAttr("text-tooltip");
			//compile changes
			$compile(el)(scope);

			//destroy resize event
			// scope.$on('$destroy', function () {
			// 	resize()
			// });
		}
	};
}]);

//TODO improve directive to eliminate unnecessary div box example: put classes and attributes from the fake input into the element div and append date_range element
angular.module('fidis-framework-smart-table').directive('datepickerRangePopup', ['$compile', function datepickerRangePopup($compile){
	return {
		restrict: 'A',
		priority:1001, // compiles first
		terminal:true, // prevent lower priority directives to compile after it
		compile: function compile(tElement, tAttrs){
			return {
				pre: function preLink(scope, el, attrs, ctrl){
					//remove own directive to prevent cycle
					el.removeAttr("datepicker-range-popup");
					//clear element html
					el.html('');
					//set index
					var index = attrs.datepickerRangePopup;
					//set wrapper
					var datepicker = '' +
						'<input date-range="'+index+'" readonly placeholder="" type="text" class="filter_input fake_input datepicker_check" ng-value="'+index+'_selected | daterange_picker_input" ng-click="status_array.'+index+' = !status_array.'+index+'"/>'+
						'<div class="date_range animate-block" ng-if="status_array.'+index+' == true">'+
						'<input date-range="'+index+'" readonly placeholder="" type="text" class="filter_input fake_input datepicker_check" ng-value="'+index+'_selected | daterange_picker_input" ng-click="status_array.'+index+' = !status_array.'+index+'"/>'+
						'<div uib-datepicker ng-model="'+index+'" multi-select="'+index+'_selected" select-range="true" datepicker-options="range_options.'+index+'"></div>'+
						'<button type="button" class="btn btn-sm btn-danger range-ok" ng-click="status_array.'+index+' = !status_array.'+index+'">{{ \'DATE_OK\' | translate }}</button>'+
						'<button type="button" class="btn btn-sm btn-danger range-clear" ng-click="clear_range(\''+index+'_selected\')">{{ \'DATE_CLEAR\' | translate }}</button>'+
						'</div>';
					//overwrite element html
					el.html(datepicker);

					//compile element
					$compile(el)(scope);
				}
			};
		}
	};
}]);

angular.module('fidis-framework-smart-table').directive('selectDelete',['$compile', function selectDelete($compile){
	return {
		restrict: 'A',
		scope: true,
		link: function(scope, el, attrs, ctrl) {
			var listener = scope.$watch(attrs.selectDelete, function(value){
				//destroy watcher
				listener();
				//remove directive to prevent infinite loop
				el.removeAttr("select-delete");

				var ngif_check = true;
				if (value.length > 1) {
					ngif_check = value[1];
				}
				el.empty();
				el.append('<input type="checkbox" class="checkbox" ng-if="'+ngif_check+'" ng-click="select_deletions('+value[0]+')" />');
				$compile(el)(scope);
			});
		}
	};
}]);

angular.module('fidis-framework-smart-table').directive('stSelect',['$compile', function stSelect($compile){
	return {
		restrict: 'A',
		require: '^stTable',
		scope: {
			stSelect: '=',
			stSelectOptions: '='
		},
		link: function(scope, el, attrs, table) {
			var strict, is_object, allow_empty,
				value_all = '########';
			if(scope.stSelectOptions !== undefined){
				strict = (scope.stSelectOptions.strict !== undefined)?scope.stSelectOptions.strict:true;
				is_object = (scope.stSelectOptions.object !== undefined)?scope.stSelectOptions.object:false;
				allow_empty = (scope.stSelectOptions.allow_empty !== undefined)?scope.stSelectOptions.allow_empty:false;
			}else{
				strict = true;
				is_object = false;
				allow_empty = false;

			}
			var emtpy = ' ';
			if(allow_empty)
				emtpy = ' allow_empty="true" ';
			//set template
			if(is_object == true){
				el.append('<option value="'+value_all+'" translate="TABLEINFO_ALL"></option><option ng-repeat="type in stSelect" value="{{::type.id}}" '+emtpy+'>{{type.name|translate}}</option>');
			}else{
				el.append('<option value="'+value_all+'" translate="TABLEINFO_ALL"></option><option ng-repeat="type in stSelect" value="{{::type}}" '+emtpy+'>{{type}}</option>');
			}

			//set onchange
			el.bind('change', function(){
				var tableState = {};
				var parent;

				if(scope.$parent.tableState !== undefined){
					parent = scope.$parent;
					tableState = parent.tableState;
				}else{
					parent = scope.$parent.$parent;
					tableState = parent.tableState;
				}
				if(tableState['search']['predicateObject'] === undefined)
					tableState['search']['predicateObject'] = {};
				var value = el[0].options[el[0].selectedIndex].value;
				var empty_allowed = angular.element(el[0].options[el[0].selectedIndex]).attr('allow_empty');
				//delete entry if value is an empty string
				if(value != value_all && (value != '' || (empty_allowed == undefined || empty_allowed == true || empty_allowed == 'true'))){
					tableState['search']['predicateObject'][attrs.stSelectSearch] = {
						value: el[0].options[el[0].selectedIndex].value,
						strict: strict
					};
				}else{
					delete(tableState['search']['predicateObject'][attrs.stSelectSearch]);
				}
				//get call server
				var el_callserver = el;
				while ((el_callserver = el_callserver.parent()) && (el_callserver.attr('st-pipe') === undefined)){}
				//call server
				if(el_callserver.attr('st-pipe') !== undefined){
					parent[el_callserver.attr('st-pipe')](tableState);
				}
			});

			el.removeAttr("st-select");
			//compile changes
			$compile(el)(scope);
		}
	};
}]);

angular.module('fidis-framework-smart-table').directive('yesNo',['$compile', function yesNo($compile){
	return {
		restrict: 'A',
		scope: true,
		link: function(scope, el, attrs, ctrl) {
			//WARNING IE can't handle el.html() if in the element is an interpolation like {{row[1]}}
			el.empty();
			//delete array notation
			attrs.yesNo = attrs.yesNo.slice(0, -1);
			attrs.yesNo = attrs.yesNo.slice(1);
			var content = '{{(('+attrs.yesNo+' == "1" || '+attrs.yesNo+'.toUpperCase() == "X")?"ACTION_YES":"ACTION_NO")|translate}}';
			//overwrite element html
			el.append(content);
			el.removeAttr("yes-no");
			//compile changes
			$compile(el)(scope);
		}
	};
}]);

//preparation bytes
angular.module('fidis-framework-smart-table').filter('daterange_picker_input', ['$rootScope', '$filter', 'SessionService', function daterange_picker_input($rootScope, $filter, SessionService) {
	return function(array) {
		if(array !== undefined && array.length > 0){
			var from;
			var to = '';
			from = $filter('date')($filter('orderBy')(array)[0], SessionService.session.settings.date_format.display);
			if(array.length > 1)
				to = ' - '+$filter('date')($filter('orderBy')(array, '-')[0], SessionService.session.settings.date_format.display);
			return from+to;
		}else{
			return '';
		}
	}
}]);

//integer filter
angular.module('fidis-framework-smart-table').filter('integer', ['$filter', function integer($filter){
	return function(input, numbergroup){
		if(input !== undefined && input !== '' && input !== null){
			input = $filter('number')(input);
			if(numbergroup !== undefined && numbergroup !== '' && numbergroup !== null)
				return input.toString().replace(',', numbergroup);
			else
				return input.toString();
		}else{
			return 0;
		}

	};
}]);