angular.module('fidis-framework-frame', ['fidis-framework-config']);

//top navigation bar
angular.module('fidis-framework-frame').directive('navbarHeader', ['fidisFrameworkConfig', function(fidisFrameworkConfig){
	return {
		restrict:    'E',
		controller: 'MenuHeaderCtrl',
		templateUrl : function(params){
			if(fidisFrameworkConfig.frame !== undefined && fidisFrameworkConfig.frame.navbarHeader !== undefined && fidisFrameworkConfig.frame.navbarHeader != ''){
				return fidisFrameworkConfig.frame.navbarHeader;
			}else{
				return 'navbarHeader/tpl';
			}
		},
		replace:     true
	};
}]);

//top navigation bar
angular.module('fidis-framework-frame').directive('navbarTop', ['fidisFrameworkConfig', function(fidisFrameworkConfig){
	return {
		restrict:    'E',
		templateUrl : function(params){
			if(fidisFrameworkConfig.frame !== undefined && fidisFrameworkConfig.frame.navbarTop !== undefined && fidisFrameworkConfig.frame.navbarTop != ''){
				return fidisFrameworkConfig.frame.navbarTop;
			}else{
				return 'navbarTop/tpl';
			}
		}
	};
}]);

//top navigation bar
angular.module('fidis-framework-frame').directive('navbarTopMobile', ['fidisFrameworkConfig', function(fidisFrameworkConfig){
	return {
		restrict:    'E',
		templateUrl : function(params){
			if(fidisFrameworkConfig.frame !== undefined && fidisFrameworkConfig.frame.navbarTopMobile !== undefined && fidisFrameworkConfig.frame.navbarTopMobile != ''){
				return fidisFrameworkConfig.frame.navbarTopMobile;
			}else{
				return 'navbarTopMobile/tpl';
			}
		}
	};
}]);

//side navigation bar
angular.module('fidis-framework-frame').directive('navbarSide', ['fidisFrameworkConfig', function(fidisFrameworkConfig){
	return {
		restrict:    'E',
		controller: 'MenuSideCtrl',
		templateUrl : function(params){
			if(fidisFrameworkConfig.frame !== undefined && fidisFrameworkConfig.frame.navbarSide !== undefined && fidisFrameworkConfig.frame.navbarSide != ''){
				return fidisFrameworkConfig.frame.navbarSide;
			}else{
				return 'navbarSide/tpl';
			}
		},
		replace:     true
	};
}]);

//footer navigation bar
angular.module('fidis-framework-frame').directive('navbarFooter', ['fidisFrameworkConfig', function(fidisFrameworkConfig){
	return {
		controller:  ['$scope', function FooterCtrl($scope){
			$scope.version       = fidisFrameworkConfig.version;
			$scope.branding_name = fidisFrameworkConfig.branding_name;
			$scope.year          = fidisFrameworkConfig.year;
			$scope.name          = fidisFrameworkConfig.name;
		}],
		restrict:    'E',
		templateUrl : function(params){
			if(fidisFrameworkConfig.frame !== undefined && fidisFrameworkConfig.frame.navbarFooter !== undefined && fidisFrameworkConfig.frame.navbarFooter != ''){
				return fidisFrameworkConfig.frame.navbarFooter;
			}else{
				return 'navbarFooter/tpl';
			}
		},
		replace:     true
	};
}]);