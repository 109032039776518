angular.module('fidis-framework-modal-info', ['ui.bootstrap.modal']);

//service to handle info modal windows like delete check
angular.module('fidis-framework-modal-info').factory('InfoModalFactory', ['$uibModal', function InfoModalFactory($uibModal){
	var factory = {};

	factory.info = function(category, type, data, callback){
		var modalInstance = $uibModal.open(
			{
			   animation:   true,
			   backdrop:    'static',
			   keyboard:    false,
			   templateUrl: 'InfoModal/tpl',
			   controller:  'InfoModalCtrl',
			   resolve:     {
				   data: function(){
					   return {msg: data, category: category, type: type};
				   }
			   }
		   }).result.then(function(result){
				callback('save', result);
			}, function(result){
				callback('cancel', result);
			});
	};

	return factory;
}]);

angular.module('fidis-framework-modal-info').controller('InfoModalCtrl', ['$scope', '$rootScope', '$uibModalInstance', 'data', '$http', '$timeout', 'fidisFrameworkConfig', function($scope, $rootScope, $uibModalInstance, data, $http, $timeout, fidisFrameworkConfig){
	var path          = $rootScope.globals.next.slice(1)+'/req';
	$scope.check_save = false;
	$scope.type       = data.type.toUpperCase();
	$scope.category 	= data.category.toUpperCase();
	$scope.submit = false;
	//delete document
	$scope.ok     = function(){
		if(data.msg !== ''){
			if($scope.submit == false){
				$scope.submit = true;
				$http.post(path, {msg: data.msg, type: data.category})
					 .then(function(response){
						 //show appropriate info
						 $scope.message      = {};
						 $scope.message.type = response.data.type;
						 $scope.message.text = response.data.text;

						 if(response.data.type === 'success'){
							 $scope.check_save = true;
							 $scope.return_val = {
								 check: $scope.check_save
							 };
							 $timeout(function(){
								 $uibModalInstance.close($scope.return_val);
							 }, fidisFrameworkConfig.timeout_modal, false);
						 }else{
							 $scope.submit = false;
						 }
					 });
			}
		}else{
			$scope.check_save = true;
			$uibModalInstance.close({
										check: $scope.check_save
									});
		}
	};

	//cancel modal window
	$scope.cancel = function(){
		if($scope.check_save === false){
			$scope.return_val = {check: $scope.check_save};
		}
		$uibModalInstance.close($scope.return_val);
	};

	//close alert box
	$scope.closeAlert = function(){
		$scope.message = undefined;
	};

}]);